import { createSelector } from 'reselect';

import { namespace } from './constants';
import { initialState } from './state';

const selectState = state => state.get(namespace, initialState);

export const selectCampaigns = createSelector(selectState, state =>
  state.get('campaigns').toJS(),
);

export const selectTeams = createSelector(selectState, state =>
  state.get('teams').toJS(),
);

export const selectCurrentTeam = createSelector(selectState, state =>
  state.get('currentTeam'),
);

export const selectIsLoading = createSelector(selectState, state =>
  state.get('isLoading'),
);
