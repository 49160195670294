/*
 *
 * CampaignAdminReview actions
 *
 */

import {
  CAMPAIGN_ADMIN_REVIEW_LOAD_SUCCEEDED,
  CAMPAIGN_ADMIN_REVIEW_LOAD_REQUESTED,
  CAMPAIGN_ADMIN_REVIEW_LOAD_FAILED,
} from './constants';

/**
 * Trigger to load draft campaign admin data
 *
 * @param {string} draftUuid
 *
 * @returns {object} reducer action with corresponding payload
 */
export function campaignAdminReviewLoadRequested(draftUuid) {
  return {
    type: CAMPAIGN_ADMIN_REVIEW_LOAD_REQUESTED,
    draftUuid,
  };
}

/**
 * Signal that campaign admin data were loaded.
 *
 * @param {object} draftCampaign
 *
 * @returns {object} reducer action with corresponding payload
 */
export function campaignAdminReviewLoadSucceeded(draftCampaign) {
  return {
    type: CAMPAIGN_ADMIN_REVIEW_LOAD_SUCCEEDED,
    draftCampaign,
  };
}

/**
 * Signal that campaign admin data were NOT loaded because of an exception
 *
 * @param {object} error - error message or object.
 *
 * @returns {object} reducer action with corresponding payload
 */
export function campaignAdminReviewLoadFailed(error) {
  return {
    type: CAMPAIGN_ADMIN_REVIEW_LOAD_FAILED,
    error,
  };
}
