import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import {
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';

/**
 * Helper function for rendering the media partners.
 *
 * @param {object} props
 * @param {object} props.headerText
 * @param {object[]} props.items
 * @param {object} props.noneSelectedText - backup text
 * @returns {Node}
 */
function ListOfValues({ headerText, items, noneSelectedText }) {
  return (
    <>
      <TableHead>
        <TableRow>
          <TableCell component="th">
            <Typography variant="body2">
              <FormattedMessage {...headerText} />
            </Typography>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {items.length ? (
          items.map(({ key, display }) => (
            <TableRow key={key}>
              <TableCell>{display}</TableCell>
            </TableRow>
          ))
        ) : (
          <TableRow>
            <TableCell>
              <Typography variant="body2">
                <FormattedMessage {...noneSelectedText} />
              </Typography>
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </>
  );
}

ListOfValues.propTypes = {
  headerText: PropTypes.object.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      display: PropTypes.node.isRequired,
    }),
  ).isRequired,
  noneSelectedText: PropTypes.object.isRequired,
};

export default ListOfValues;
