/**
 *
 * Link
 *
 */

import React from 'react';
import { Link as ReactRouterLink } from 'react-router-dom';
import MuiLink from '@material-ui/core/Link';
import PropTypes from 'prop-types';

/* eslint-disable react/prefer-stateless-function */
class Link extends React.PureComponent {
  render() {
    return (
      <MuiLink component={ReactRouterLink} {...this.props}>
        {this.props.children}
      </MuiLink>
    );
  }
}

Link.propTypes = {
  children: PropTypes.node,
};

export default Link;
