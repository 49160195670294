import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  field: {
    marginRight: theme.spacing(1),
  },
}));

/**
 * Information field
 *
 * @param {object} props
 * @param {object} props.label - the intl field label to display
 * @param {string} [props.value=''] - the field value
 *
 * @returns {React.Component}
 */
function InfoField({ label, value = '' }) {
  const classes = useStyles();

  return (
    <div>
      <Typography component="span" className={classes.field} variant="body2">
        <FormattedMessage {...label} />
      </Typography>
      <Typography component="span" variant="body2">
        {value}
      </Typography>
    </div>
  );
}

InfoField.propTypes = {
  label: PropTypes.object.isRequired,
  value: PropTypes.string,
};

export default InfoField;
