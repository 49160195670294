/**
 * InviteUserToTeam
 */

import { useState } from 'react';
import { useIntl } from 'react-intl';

import { fillArray } from 'utils/array';
import { useNoticeManagerHook } from 'containers/NoticeManager/useNoticeManagerHook';
import messages from './messages';
import { addUserToTeam } from './services';

/**
 * @typedef Team
 * @property {string} teamId
 * @property {string} teamName
 */

/**
 * InviteUserToTeam
 *
 * @param {Team} team - The team users will be invited to
 * @param {Function} onClose - Callback to close the modal
 * @returns {object} - All the state and aux functions for the handling of the invite users modal
 */
function useInviteUserToTeam(team, onClose) {
  const intl = useIntl();

  const { postNotice } = useNoticeManagerHook();
  const [users, setUsers] = useState([]);

  const [editing, setEditing] = useState(null);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [errors, setErrors] = useState({});

  const [submitting, setSubmitting] = useState(false);

  /**
   * Add a user to the list
   */
  function addUser() {
    const index = users.findIndex(
      listUser => editing?.email === listUser.email,
    );
    const user = {
      firstName,
      lastName,
      email,
    };

    const tmpUsers = [...users];
    if (index === -1) {
      tmpUsers.push(user);
    } else {
      tmpUsers[index] = user;
    }

    setUsers(tmpUsers);
    setFirstName('');
    setLastName('');
    setEmail('');
    setEditing(null);
  }

  /**
   * Add a user to the list
   *
   * @param {object} user - The user to be edited
   */
  function editUser(user) {
    setFirstName(user?.firstName);
    setLastName(user?.lastName);
    setEmail(user?.email);
    setEditing(user);
  }

  /**
   * Cancel the edit of a user
   */
  function cancelEditUser() {
    setFirstName('');
    setLastName('');
    setEmail('');
    setEditing(null);
  }

  /**
   * Delete a user from the list
   *
   * @param {object} user - The user to be edited
   */
  function deleteUser(user) {
    const index = users.findIndex(listUser => user.email === listUser.email);
    const tmpUsers = [...users];
    tmpUsers.splice(index, 1);
    setUsers(tmpUsers);
  }

  /**
   * Handle the submition of the form
   */
  function onSubmit() {
    // If there are no errors
    setSubmitting(true);
    addUserToTeam(users, team.teamId)
      .then(res => {
        setSubmitting(false);
        if (!res.error) {
          postNotice(intl.formatMessage(messages.success));
          onClose();
        } else {
          // If the result was not an error it means invalid input
          setErrors({ generalError: res.msg });
        }
      })
      .catch(() => {
        setSubmitting(false);
        setErrors({ generalError: intl.formatMessage(messages.serverError) });
      });
  }

  const userCanBeAddedToTeam =
    firstName.length > 0 &&
    lastName.length > 0 &&
    email.length > 0 &&
    (!users.some(user => user.email === email) || editing?.email === email);

  const emptyUsers =
    users.length > 0 && users.length < 5
      ? fillArray([], 5 - users.length, null)
      : [];

  const addButtonLabel = !editing ? messages.addForInvitation : messages.update;

  return {
    firstName,
    setFirstName,
    lastName,
    setLastName,
    email,
    setEmail,
    editing,
    setEditing,
    users,
    errors,
    submitting,
    emptyUsers,
    userCanBeAddedToTeam,
    addButtonLabel,
    //
    addUser,
    editUser,
    cancelEditUser,
    deleteUser,
    onSubmit,
  };
}

export { useInviteUserToTeam };
