/*
 *
 * LanguageProvider reducer
 *
 */

import { fromJS } from 'immutable';

import { DEFAULT_LOCALE } from 'i18n';

import { CHANGE_LOCALE } from './constants';

export const initialState = fromJS({
  locale: DEFAULT_LOCALE,
});

/**
 * Reducer function.
 *
 * @param {object} [state=initialState]
 * @param {object} action
 *
 * @returns {object}
 */
function languageProviderReducer(state = initialState, action) {
  switch (action.type) {
    case CHANGE_LOCALE:
      return state.set('locale', action.locale);
    default:
      return state;
  }
}

export default languageProviderReducer;
