import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import TableCell from './TableCell';

import { useStyles } from './styled';

/**
 * Custom wrapper for MUI TableContainer
 *
 * @param {object} props
 * @param {string} [props.component = 'th']
 * @param {object} [props.className]
 *
 * @returns {React.Component}
 */
function TableLabelCell({ className, component = 'th', ...props }) {
  const classes = useStyles();

  return (
    <TableCell
      component={component}
      className={clsx(className, classes.label)}
      {...props}
    />
  );
}

TableLabelCell.propTypes = {
  className: PropTypes.string,
  component: PropTypes.string,
};

export default TableLabelCell;
