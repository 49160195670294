import * as Sentry from '@sentry/react';

import { FetchError } from 'utils/FetchService';

/**
 * Capture an exception for Sentry with additional context where available.
 *
 * @param {Error} error the exception to capture
 * @param {object} context any additional context to capture in Sentry
 */
export function captureException(error, context = {}) {
  Sentry.withScope(scope => {
    let requestContext = {};
    const originalMessage = error.message;
    if (error instanceof FetchError) {
      // The Sentry API doesn't report much useful information for fetch errors
      // by default, so let's augment it with some additional context.
      // Add a tag for the truncated URL so we can search for it.
      const truncatedUrl = truncateUrlIfRequired(error.url);
      scope.setTag('fetchUrl', truncatedUrl);

      // Save the original error message, but then replace it to allow Sentry
      // to group similar errors together.
      // eslint-disable-next-line no-param-reassign
      error.message = generateFetchErrorMessage(error, truncatedUrl);

      // Add extra info about the request, so it appears on the sentry report
      requestContext = {
        method: error.method,
        url: error.url,
        statusCode: error.response?.status,
        statusText: error.response?.statusText,
        originalMessage,
        executionTimeMillis: error.executionTimeMillis,
      };
      scope.setContext('Request', requestContext);
    }

    // Include any additional context from the caller
    Object.keys(context).forEach(key => scope.setContext(key, context[key]));

    // Log the error to the console so that there is some evidence of a problem
    // occurring if we happen to have the JS console open
    // eslint-disable-next-line no-console
    console.log(
      `Reporting error '${error.message}' to Sentry (original message was '${originalMessage}'`,
      { ...context, ...requestContext },
      error,
      error.cause,
    );
    Sentry.captureException(error);
  });
}

/**
 * Truncates the URL for a Sentry tag if required. Tags have a max length of 200
 * characters, and we also want to strip all query parameters.
 *
 * @param {string} url the URL
 * @returns {string} the truncated URL
 */
function truncateUrlIfRequired(url) {
  if (!url) {
    return url;
  }

  const index = url.indexOf('?');
  const urlWithoutParams = index > 0 ? url.substring(0, index) : url;
  return urlWithoutParams.substring(0, 200);
}

/**
 * Generates the error message to use for reporting to Sentry. We differentiate
 * between 4xx errors, 5xx errors, and errors with no response available. We
 * include a partial URL in the message, but strip all UUIDs so that common
 * errors can be grouped across campaigns.
 *
 * @param {FetchError} error the fetch error
 * @returns {string} the error message
 */
function generateFetchErrorMessage(error) {
  let baseMessage = 'Failed to fetch';
  if (error.response?.status) {
    const statusLevel = Math.floor(error.response.status / 100);
    baseMessage = `${baseMessage} (${statusLevel}xx)`;
  }

  // Regex replacement is done prior to truncation so that we can keep as much
  // of the original URL as possible.
  const uuidRegex =
    /[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}/g;
  const url = truncateUrlIfRequired(error.url.replace(uuidRegex, '<uuid>'));
  return `${baseMessage} ${url}`;
}
