/*
 * AppContent Messages
 *
 * This contains all the text for the AppContent container.
 */

import { defineMessages } from 'react-intl';
import { generateMessageMap } from 'utils/messages';

import { namespace } from './constants';

export const scope = `app.containers.${namespace}`;

export default defineMessages(
  generateMessageMap(scope, {
    // Menu labels
    brands: 'Brands',
    brandCampaigns: 'Brand Campaigns',
    campaigns: 'Campaigns',
    portfolios: 'Portfolio',
  }),
);
