import React from 'react';
import PropTypes from 'prop-types';

export const HeaderContext = React.createContext();

/**
 * Provides label state context for Header
 *
 * @param {object} props
 * @param {Node} props.children
 *
 * @returns {React.Component}
 */
function HeaderProvider({ children }) {
  const [pageName, setPageName] = React.useState('');
  const [contextName, setContextName] = React.useState('');
  const [goBack, setGoBack] = React.useState('');

  const value = React.useMemo(
    () => ({
      pageName,
      setPageName,
      contextName,
      setContextName,
      goBack,
      setGoBack,
    }),
    [pageName, contextName],
  );

  return (
    <HeaderContext.Provider value={value}> {children} </HeaderContext.Provider>
  );
}

HeaderProvider.propTypes = {
  children: PropTypes.node,
};

export default HeaderProvider;
