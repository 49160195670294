import { defineMessages } from 'react-intl';
import { generateMessageMap } from 'utils/messages';

export const scope = 'app.containers.InviteUserToTeam';

export default defineMessages(
  generateMessageMap(scope, {
    inviteUser: 'Invite Users',
  }),
);
