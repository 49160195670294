import { createSelector } from 'reselect';
import { initialState } from './reducer';

export const selectLanguage = state => state.get('language', initialState);

/**
 * Select the language locale
 */

export const selectLocale = createSelector(selectLanguage, languageState =>
  languageState.get('locale'),
);
