/*
 * App Actions
 *
 * Actions change things in your application
 * Since this boilerplate uses a uni-directional data flow, specifically redux,
 * we have these actions which are the only way your application interacts with
 * your application state. This guarantees that your state is up to date and nobody
 * messes it up weirdly somewhere.
 *
 * To add a new Action:
 * 1) Import your constant
 * 2) Add a function like this:
 *    export function yourAction(var) {
 *        return { type: YOUR_ACTION_CONSTANT, var: var }
 *    }
 */

import {
  LOAD_CURRENT_USER,
  CURRENT_USER_LOADED,
  CONFOUNDER_TYPES_LOADED,
  CONFOUNDER_CATEGORIES_LOADED,
  LOAD_MEDIA_PARTNERS,
  MEDIA_PARTNERS_LOADED,
} from './constants';

/**
 * Load the repositories, this action starts the request saga
 *
 * @returns {object} An action object with a type of LOAD_CURRENT_USER
 */
export function loadCurrentUser() {
  return {
    type: LOAD_CURRENT_USER,
  };
}

/**
 * Current user has been loaded action
 *
 * @param {object} user
 * @returns {object} An action object with a type of CURRENT_USER_LOADED
 */
export function currentUserLoaded(user) {
  return {
    type: CURRENT_USER_LOADED,
    user,
  };
}

/**
 * Confounder Types have been loaded
 *
 * @param {object[]} confounderTypes
 *
 * @returns {object} An action with a type of CONFOUNDER_TYPES_LOADED
 */
export function confounderTypesLoaded(confounderTypes) {
  return {
    type: CONFOUNDER_TYPES_LOADED,
    confounderTypes,
  };
}

/**
 * Confounder Categories have been loaded
 *
 * @param {object[]} confounderCategories
 *
 * @returns {object} An action with a type of CONFOUNDER_CATEGORIES_LOADED
 */
export function confounderCategoriesLoaded(confounderCategories) {
  return {
    type: CONFOUNDER_CATEGORIES_LOADED,
    confounderCategories,
  };
}

/**
 * Load the list of Media Partners
 *
 * @param {number} page
 *
 * @returns {object} An action with a type of LOAD_MEDIA_PARTNERS
 */
export function loadMediaPartners(page) {
  return {
    type: LOAD_MEDIA_PARTNERS,
    page,
  };
}

/**
 * Media Partners have been loaded
 *
 * @param {object[]} mediaPartners
 * @param {boolean} resetList
 * @param {boolean} last
 *
 * @returns {object} An action with a type of MEDIA_PARTNERS_LOADED
 */
export function mediaPartnersLoaded(mediaPartners, resetList, last) {
  return {
    type: MEDIA_PARTNERS_LOADED,
    mediaPartners,
    resetList,
    last,
  };
}
