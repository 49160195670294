import styled from 'styled-components';
import theme from 'utils/theme';

export const AuthContent = styled.main`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: linear-gradient(
    180deg,
    ${theme.background.default} calc(50% - 3px),
    ${theme.palette.primary.dark} calc(50% - 3px),
    ${theme.palette.primary.dark} calc(50%),
    ${theme.palette.primary.dark} calc(50% + 3px),
    ${theme.palette.common.white} calc(50% + 3px)
  );
`;

export const MainContent = styled.main`
  flex: 1;

  display: flex;
  flex-direction: column;
  overflow: auto;

  background-color: ${theme.palette.primary.light};
`;

export const LoadingIndicatorContainer = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.8);
`;
