import FetchService from 'utils/FetchService';
import { buildUri } from 'utils/uri';

/**
 * Validates the form data
 *
 * @param {[{firstName: string, lastName: string, email: string}]} data
 * @param {string} data.email
 * @param {string} data.firstName
 * @param {string} data.lastName
 * @returns {object}
 */
export function validateInviteData(data) {
  const errors = {};
  data.forEach(user => {
    if (!user.email || !user.email.trim().length) {
      errors.email = true;
    }
    if (!user.firstName || !user.firstName.trim().length) {
      errors.firstName = true;
    }
    if (!user.lastName || !user.lastName.trim().length) {
      errors.lastName = true;
    }
  });

  return errors;
}

/**
 * Promise to load Teams list
 *
 * @param {[{}]} data
 * @param {string} teamId
 * @param {string} data.teamId
 * @param {string} data.email
 * @param {string} data.firstName
 * @param {string} data.lastName
 * @returns {Promise}
 */
export function addUserToTeam(data, teamId) {
  const url = buildUri('user-team:create', { teamId });
  return FetchService.post(url, { data });
}
