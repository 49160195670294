import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';
import { CircularProgress } from '@material-ui/core';

const useStyles = makeStyles(
  () => ({
    root: {
      position: 'absolute',
      width: '100%',
      height: '100%',
      inset: 0,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      zIndex: 100,
    },
    rootRelative: {
      position: 'relative',
      width: '100%',
      height: '100%',
      inset: 0,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      zIndex: 100,
    },
    obscure: {
      backgroundColor: 'rgba(255, 255, 255, 0.75)',
    },
  }),
  { name: 'LoadingIndicator' },
);

/**
 * Loading Indicator
 *
 * @param {object} props
 * @param {boolean} [props.obscure] - obscure the underlying content
 * @param {Node} [props.topMessage]
 * @param {Node} [props.bottomMessage]
 * @param {boolean} [props.relative]
 *
 * @returns {React.Component} - The loading indicator component
 */
function LoadingIndicator({
  obscure,
  topMessage,
  bottomMessage,
  relative = false,
}) {
  const classes = useStyles();
  const indicatorClass = relative
    ? clsx(classes.rootRelative, { [classes.obscure]: obscure })
    : clsx(classes.root, { [classes.obscure]: obscure });

  return (
    <div data-testid="loading-indicator" className={indicatorClass}>
      {topMessage}
      <CircularProgress />
      {bottomMessage}
    </div>
  );
}

LoadingIndicator.propTypes = {
  obscure: PropTypes.bool,
  topMessage: PropTypes.node,
  bottomMessage: PropTypes.node,
  relative: PropTypes.bool,
};

export default LoadingIndicator;
