import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { useStyles } from './styled';

/**
 * Table
 *
 * @param {object} props
 * @param {string} [props.className]
 * @param {Node} [props.children]
 * @param {number} [props.fixedCols]
 *
 * @returns {React.Component}
 */
function Table({ className, children, fixedCols, ...props }) {
  const classes = useStyles({ fixedCols });

  const ref = React.createRef();

  // Set the `left` style
  // It doesn't matter if we set it for non-fixed columns as their positioning
  // will ignore it.
  React.useLayoutEffect(() => {
    if (fixedCols) {
      ref.current.querySelectorAll('tr').forEach(row => {
        Array.from(row.querySelectorAll('td, th')).reduce((offset, cell) => {
          /* eslint-disable-next-line no-param-reassign */
          cell.style.left = `${offset}px`;
          return offset + cell.offsetWidth; // extra size for border
        }, 1);
      });
    }
    // this must depend on children so it recalculates if the contents are updated
  }, [children, ref, fixedCols]);

  return (
    <table
      ref={ref}
      className={clsx(
        classes.table,
        { [classes.fixedCols]: fixedCols },
        className,
      )}
      {...props}
    >
      {children}
    </table>
  );
}

Table.propTypes = {
  className: PropTypes.string,
  fixedCols: PropTypes.number,
  children: PropTypes.node,
};

export default Table;
