import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(
  theme => ({
    root: {
      backgroundColor: theme.palette.survata.interface.white,
      minWidth: '400px',
      height: '42px',
      margin: theme.spacing(1, 2),
      border: [['solid', 1, theme.palette.survata.interface.bluegrey]],
    },
    menuItem: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    inviteUser: {
      color: theme.palette.primary.main,
      backgroundColor: 'transparent',
      cursor: 'pointer',
      textTransform: 'none',

      '&:hover, &:active': {
        backgroundColor: 'transparent',
      },
    },
  }),
  { name: 'TeamSwitch' },
);
