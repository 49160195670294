import React from 'react';

/**
 * Utility function to help generate message maps.
 *
 * @param {string} scope - a prefix for all keys
 * @param {object} items - key is message ID, value is defaultMessage.
 *
 * @returns {object}
 */
export function generateMessageMap(scope, items) {
  return Object.entries(items).reduce((acc, [key, defaultMessage]) => {
    acc[key] = {
      id: `${scope}.${key}`,
      defaultMessage,
    };
    return acc;
  }, {});
}

/*
 * Common supported elements in Formatted HTML Messages
 */
export const commonTags = {
  // FormatJS refuses to support self-closing elements, and recommend this hack
  // to work around it. See https://github.com/formatjs/formatjs/issues/1616
  br: <br />,
  div: chunks => <div>{chunks}</div>,
  p: chunks => <p>{chunks}</p>,
  span: chunks => <span>{chunks}</span>,
  strong: chunks => <strong>{chunks}</strong>,
  em: chunks => <em>{chunks}</em>,
  ul: chunks => <ul>{chunks}</ul>,
  li: chunks => <li>{chunks}</li>,
};
