import React from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import Button from 'components/Button';

const useStyles = makeStyles(
  theme => ({
    title: {
      fontWeight: 'bold',
    },
    content: {
      marginBottom: theme.spacing(8),
    },
    actionsMessage: {
      flexGrow: 1,
      marginLeft: theme.spacing(2),
    },
  }),
  { name: 'Modal' },
);

/**
 * Callback prototypes:
 *
 * @callback onPrimaryClick
 * @param {Event} evt
 *
 * @callback onSecondaryClick
 * @param {Event} evt
 *
 * @callback onClose
 * @param {Event} evt
 * @param {string} reason
 *
 * @callback onEscapeKeyDown -
 *
 */

/**
 * Basic modal component
 * Based upon https://material-ui.com/api/dialog/
 *
 * @param {object} props
 * @param {boolean} props.open - current display state of the modal
 * @param {Node} props.title - Content for the title of the modal
 * @param {Node} props.children - Main content of the modal
 * @param {object} [props.primaryProps] - additional props for the primary button
 * @param {object} [props.secondaryProps] - additional props for the secondary button
 * @param {Node} props.primaryButtonLabel - label of the primary button
 * @param {Node} props.secondaryButtonLabel - label of the secondary button
 * @param {Node} [props.actionsNotification = null] - notification to be added on the actions bar
 *
 * @param {onPrimaryClick} props.onPrimaryClick - Called when primary button is pressed
 * @param {onSecondaryClick} props.onSecondaryClick - Called when the secondary button is pressed
 *
 * The following useful params are inherited from Dialog
 *
 * @param {onClose} props.onClose  - callback fired when the component
 * requests to be closed
 * @param {onEscapeKeyDown} props.onEscapeKeyDown - callback fired when the
 * escape key is pressed and the modal is in focus.
 *
 * For the rest, see the docs at https://material-ui.com/api/dialog/
 *
 * @returns {React.Component}
 *
 * See https://material-ui.com/api/button/#props for a list of Button props.
 *
 * Note: the "open" property controls when the modal is displayed, and MUST be
 * updated by the caller.
 *
 */
function Modal({
  open,
  title,
  children,
  primaryProps = {},
  secondaryProps = {},
  primaryButtonLabel,
  secondaryButtonLabel,
  actionsNotification = null,
  onPrimaryClick,
  onSecondaryClick,
  ...rest
}) {
  const classes = useStyles();

  return (
    <Dialog open={open} maxWidth="sm" fullWidth {...rest}>
      <DialogTitle disableTypography>
        <Typography variant="h5" className={classes.title}>
          {title}
        </Typography>
      </DialogTitle>
      <DialogContent className={classes.content}>{children}</DialogContent>
      <DialogActions>
        {actionsNotification && (
          <div className={classes.actionsMessage}>{actionsNotification}</div>
        )}
        <Button
          fullWidth={false}
          variant="text"
          onClick={onSecondaryClick}
          {...secondaryProps}
        >
          {secondaryButtonLabel}
        </Button>
        <Button
          fullWidth={false}
          color="primary"
          variant="contained"
          onClick={onPrimaryClick}
          {...primaryProps}
        >
          {primaryButtonLabel}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

Modal.propTypes = {
  open: PropTypes.bool.isRequired,
  title: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
  primaryProps: PropTypes.object,
  secondaryProps: PropTypes.object,
  primaryButtonLabel: PropTypes.node.isRequired,
  secondaryButtonLabel: PropTypes.node.isRequired,
  actionsNotification: PropTypes.node,

  /* Callback methods */
  onPrimaryClick: PropTypes.func.isRequired,
  onSecondaryClick: PropTypes.func.isRequired,
};

export default Modal;
