/**
 * This code needs to exist because when this app was first deployed the
 * cacheControl headers were not set properly. This left the browser to it's
 * own devices when it came to caching, which is not a great solution becuase
 * each browser implements it differently. The end result was that we could not
 * guarantee what version any user was working with.
 *
 * To remedy this we need to forcefully clear the browsers cache.
 *
 * Presently, this code is only written to do the job once since it sets and reads
 * from the same localStorage key
 */

/**
 * Does the work of clearning the browser cache.
 */
async function deleteCaches() {
  try {
    const keys = await window.caches.keys();
    await Promise.all(keys.map(key => caches.delete(key)));
  } catch (err) {
    // TODO: please remove this console.log when we have some error reporting service.
    // eslint-disable-next-line no-console
    console.log('deleteCache err: ', err);
  }
}

/**
 * Resets cache on app load, ensuring that it is intended to ONLY be
 * called once the app is loaded.
 *
 * This also guards the cache reset from happening multiple times per user
 * unless for whatever reason, the user manually clears their localStorage.
 */
export function resetCacheForUpdate() {
  if (!localStorage.getItem('cacheReset')) {
    deleteCaches().then(() => {
      localStorage.setItem('cacheReset', 'yes');
    });
  }
}
