import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { useStyles } from './styled';

/**
 * Caption
 *
 * @param {object} props
 * @param {string} [props.className]
 *
 * @returns {React.Component}
 */
function Caption({ className, ...props }) {
  const classes = useStyles();

  return <caption className={clsx(classes.caption, className)} {...props} />;
}

Caption.propTypes = {
  className: PropTypes.string,
};

export default Caption;
