/*
 *
 * CampaignsOverview constants
 *
 */
import { CAMPAIGN_STATUS } from 'utils/constants';

export const namespace = 'campaignsOverview';

export const PAGE_SIZE = 10;

export const emptyCampaign = {
  uuid: 'uuid-empty',
  surveyName: '',
  impressionCount: 0,
  expectedImpressionCount: 0,
  campaignUniques: 0,
  campaignFrequency: 0,
  primaryObjective: '',
  overallLift: 0,
  proxyUniverseResult: false,
  scheduledStart: '',
  scheduledEnd: '',
  status: CAMPAIGN_STATUS.LIVE,
};

/**
 * @typedef PagedCampaigns
 * @param {object[]} loaded
 * @param {object[]} notLoaded
 */

// Filter options available to show/hide Campaigns
export const VISIBLE_STATUSES = [
  CAMPAIGN_STATUS.PRE_LAUNCH,
  CAMPAIGN_STATUS.LIVE,
  CAMPAIGN_STATUS.COMPLETED,
  CAMPAIGN_STATUS.ARCHIVED,
];

// Filter options that are selected by default
export const DEFAULT_STATUSES = [
  CAMPAIGN_STATUS.PRE_LAUNCH,
  CAMPAIGN_STATUS.LIVE,
  CAMPAIGN_STATUS.COMPLETED,
];
