import React from 'react';
import PropTypes from 'prop-types';

import { Typography } from '@material-ui/core';
import { useSectionHeadingStyles } from './styled';

/**
 * Helper component for rendering section headings
 *
 * @param {object} props
 * @param {Node} props.message
 * @param {boolean} props.isSubheading
 * @returns {React.Component}
 */
function SectionHeading({ message, isSubheading }) {
  const classes = useSectionHeadingStyles();

  return (
    <Typography
      variant={isSubheading ? 'body1' : 'h6'}
      classes={{ root: classes.boldHeader }}
      paragraph
    >
      {message}
    </Typography>
  );
}

SectionHeading.propTypes = {
  message: PropTypes.node.isRequired,
  isSubheading: PropTypes.bool,
};

export default SectionHeading;
