/*
 *
 * LanguageProvider
 *
 * this component connects the redux state language locale to the
 * IntlProvider component and i18n messages (loaded from `app/translations`)
 */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { createIntl, createIntlCache, RawIntlProvider } from 'react-intl';

import { selectLocale } from './selectors';

const cache = createIntlCache();

// Create a default intl instance
let intl = createIntl({ locale: 'en', messages: {} }, cache);

/**
 * Provide a way for non-component code to get access to the intl instance.
 *
 * @returns {object}
 */
export function getIntl() {
  return intl;
}

/**
 * Language Provider component
 *
 * @param {object} props
 * @param {string} props.locale
 * @param {object} props.messages
 * @param {Node} props.children
 *
 * @returns {React.Component}
 */
export function LanguageProvider({ locale, messages, children }) {
  // Memoize creation of intl object. This doesn't affect running the app
  // normally, but does affect the unit tests where otherwise we get a new
  // instance of `intl` each time the test scaffold renders.
  intl = React.useMemo(
    () =>
      createIntl(
        {
          locale,
          messages,
        },
        cache,
      ),
    [locale, messages],
  );

  return (
    <RawIntlProvider value={intl}>
      {React.Children.only(children)}
    </RawIntlProvider>
  );
}

LanguageProvider.propTypes = {
  locale: PropTypes.string,
  messages: PropTypes.object,
  children: PropTypes.element.isRequired,
};

const mapStateToProps = createStructuredSelector({
  locale: selectLocale,
});

export default connect(mapStateToProps)(LanguageProvider);
