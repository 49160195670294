import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { useStyles } from './styled';

import { TableContext } from './context';

/**
 * Custom wrapper for MUI TableHead
 *
 * @param {object} props
 * @param {string} [props.className]
 * @param {boolean} [props.fixed] - Header should not scroll
 *
 * @returns {React.Component}
 */
function TableHead({ className, fixed, ...props }) {
  const classes = useStyles();

  return (
    <TableContext.Provider value="head">
      <thead
        className={clsx(
          classes.thead,
          { [classes.fixedHeader]: fixed },
          className,
        )}
        {...props}
      />
    </TableContext.Provider>
  );
}

TableHead.propTypes = {
  className: PropTypes.string,
  fixed: PropTypes.bool,
};

export default TableHead;
