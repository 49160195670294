import React from 'react';
import createSvgIcon from '@material-ui/icons/utils/createSvgIcon';

/**
 * Brands Icon
 *
 * For calculation, the star points are calculated on circles of radius 5
 * and 2.2
 */
export default createSvgIcon(
  <>
    <g fill="#798094">
      <circle cx="12" cy="8" r="7" />
      <path d="M8 15q4 2 8 0v8l-4-3-4 3z" />
    </g>
    <path
      fill="#fff"
      d="M12 3l-1.293 3.22-3.462.235L9.908 8.68l-.847 3.365L12 10.2l2.939 1.845-.847-3.365 2.663-2.225-3.462-.235z"
    />
  </>,
  'Brands',
);
