/**
 * PercentFormatter
 */

import React from 'react';
import PropTypes from 'prop-types';

import { FormattedNumber } from 'react-intl';

/* eslint-disable react/prefer-stateless-function */
/* eslint-disable react/style-prop-object */
class PercentFormatter extends React.Component {
  render() {
    const { value = 0, ...rest } = this.props;
    return <FormattedNumber value={value} style="percent" {...rest} />;
  }
}

PercentFormatter.propTypes = {
  value: PropTypes.number,
};

export default PercentFormatter;
