import React from 'react';
// import PropTypes from 'prop-types';

/**
 * Default Admin component.
 * TODO: Currently just a placeholder.
 *
 * @returns {React.Component}
 */
function AdminDefault() {
  return 'Placeholder Admin content';
}

AdminDefault.propTypes = {};

export default AdminDefault;
