import { createSelector } from 'reselect';
import { initialState } from './reducer';

import { namespace } from './constants';
/**
 * Direct selector to the campaignAdminReview state domain
 */

export const selectCampaignAdminReviewDomain = state =>
  state.get(namespace, initialState);

/**
 * Other specific selectors
 */

export const selectDraftCampaign = createSelector(
  selectCampaignAdminReviewDomain,
  state => state.get('draftCampaign').toJS(),
);

export const selectLoading = createSelector(
  selectCampaignAdminReviewDomain,
  state => state.get('loading'),
);
