import React from 'react';
import PropTypes from 'prop-types';
import { TableCell, TableRow, Typography } from '@material-ui/core';
import { useDoubleCellRowStyles } from './styled';

/**
 * Helper component for rendering a data row where the first cell is the
 * header and the second cell is the data itself.
 *
 * @param {object} props
 * @param {Node} props.metadata
 * @param {Node} props.value
 * @returns {React.Component}
 */
function DoubleCellRow({ metadata, value }) {
  const classes = useDoubleCellRowStyles();

  return (
    <TableRow>
      <TableCell component="th" classes={{ root: classes.metadataCell }}>
        <Typography variant="body2">{metadata}</Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body2">{value}</Typography>
      </TableCell>
    </TableRow>
  );
}

DoubleCellRow.propTypes = {
  metadata: PropTypes.node.isRequired,
  value: PropTypes.node.isRequired,
};

export default DoubleCellRow;
