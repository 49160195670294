/*
 * AppConstants
 * Each action has a corresponding type, which the reducer knows and picks up on.
 * To avoid weird typos between the reducer and the actions, we save them as
 * constants here. We prefix them with 'yourproject/YourComponent' so we avoid
 * reducers accidentally picking up actions they shouldn't.
 *
 * Follow this format:
 * export const YOUR_ACTION_CONSTANT = 'yourproject/YourContainer/YOUR_ACTION_CONSTANT';
 */

const scope = 'app/App';

export const LOAD_CURRENT_USER = `${scope}/LOAD_CURRENT_USER`;

export const CURRENT_USER_LOADED = `${scope}/CURRENT_USER_LOADED`;
export const CONFOUNDER_TYPES_LOADED = `${scope}/CONFOUNDER_TYPES_LOADED`;
export const CONFOUNDER_CATEGORIES_LOADED = `${scope}/CONFOUNDER_CATEGORIES_LOADED`;
export const LOAD_MEDIA_PARTNERS = `${scope}/LOAD_MEDIA_PARTNERS`;
export const MEDIA_PARTNERS_LOADED = `${scope}/MEDIA_PARTNERS_LOADED`;

export const MEDIA_PARTNERS_PER_PAGE = 100;
