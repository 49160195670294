/**
 * TeamSwitch.
 */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Select, MenuItem, Button } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { useStyles } from './styled';
import messages from './messages';

/**
 * @typedef onShareTeamCallback
 * @param {object} team
 */

/**
 * Component that displays a user's teams list.
 *
 * @param {object} props
 * @param {object[]} props.teams
 * @param {string} props.initialValue
 * @param {Function} props.onChange
 * @param {onShareTeamCallback} props.onInviteIntoTeam
 * @returns {React.Component}
 */
function TeamSwitch({ initialValue, onChange, teams, onInviteIntoTeam }) {
  const classes = useStyles();
  const [currentValue, setValue] = useState(initialValue);

  // Current value is updated whenever the parent changes the initial value
  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  useEffect(() => {
    // Do not continue with the function if the current value
    // is equals to the initial value which means that the initial
    // value was updated by the parent component
    if (currentValue === initialValue) {
      return;
    }

    const team = teams.find(({ teamId }) => teamId === currentValue);
    let timeOut;
    if (team) {
      // This setTimeout is required to prevent the change from freezing
      // for a couple of seconds when the team switch happens, mainly when
      // the selected team has many campaigns
      timeOut = setTimeout(() => {
        onChange(team);
      }, 10);
    }

    // eslint-disable-next-line consistent-return
    return () => {
      if (timeOut) {
        clearTimeout(timeOut);
      }
    };
  }, [initialValue, teams, onChange, currentValue]);

  /**
   * Team selection.
   *
   * @param {object} event - selection
   */
  const handleChange = event => {
    if (!event.isInviteIntoTeam) {
      setValue(event.target.value);
    }
  };

  /**
   * Select team for invite.
   *
   * @param {object} event
   * @param {object} team
   */
  const handleTeamSelectionForInvite = (event, team) => {
    event.stopPropagation();

    // This setTimeout is required to prevent the change from freezing
    // for a couple of seconds when the invite user is clicked
    setTimeout(() => {
      onInviteIntoTeam(team);
    }, 10);
  };

  return (
    <Select
      aria-label="team switch"
      variant="outlined"
      className={classes.root}
      onChange={handleChange}
      value={currentValue}
      MenuProps={{
        'aria-label': 'team switch menu',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'left',
        },
        getContentAnchorEl: null,
      }}
      renderValue={value =>
        teams.find(({ teamId }) => teamId === value).teamName
      }
    >
      {teams.map(team => (
        <MenuItem
          key={team.teamId}
          value={team.teamId}
          className={classes.menuItem}
        >
          <span>{team.teamName}</span>
          <Button
            type="button"
            onClick={ev => handleTeamSelectionForInvite(ev, team)}
            className={classes.inviteUser}
          >
            <FormattedMessage {...messages.inviteUser} />
          </Button>
        </MenuItem>
      ))}
    </Select>
  );
}

TeamSwitch.propTypes = {
  initialValue: PropTypes.string.isRequired,
  teams: PropTypes.array.isRequired,

  // actions
  onChange: PropTypes.func.isRequired,
  onInviteIntoTeam: PropTypes.func.isRequired,
};

export default TeamSwitch;
