/**
 * Urls that should not generate a returnTo
 */
export const ignoredReturnToUrls = [
  '',
  '/',
  '/login',
  '/sign-up',
  '/password-reset',
];

/**
 * creates the redirectTo based on the current URL
 *
 * @returns {string} UrlSearchParams with the redirectTo attribute
 */
export function createRedirectTo() {
  // Parse url
  const parsedUrl = new URL(window.location.href);

  // Set default redirectTo
  let redirectTo = '';

  // Change the redirectTo to the current pathname if not in the ignored list
  if (ignoredReturnToUrls.indexOf(parsedUrl.pathname) === -1) {
    const params = new URLSearchParams({});
    params.append('redirectTo', window.location.href);
    redirectTo = `${params.toString()}`;
  }

  return redirectTo;
}

/**
 * Parses the redirectTo parameter from the URL
 *
 * @returns {string} RedirectTo attribute from url
 */
export function getRedirectToFromURL() {
  // Cerate URLSearchParams from current location search params
  const params = new URLSearchParams(window.location.search);

  // Get and return the attribute redirectTo from the generated URLSearchParams
  return params.get('redirectTo');
}

/**
 * Extracts the pathname of the search parameter redirectTo
 *
 * @param {string} defaultPath - Default path if no redirectTo attribute is present in url
 * @returns {string} Pathname in the redirectTo parameter
 */
export function getRedirectToPathFromURL(defaultPath) {
  // Get the attribute redirectTo from url
  const redirectTo = getRedirectToFromURL();

  // Set the path default value
  let path = defaultPath;

  if (redirectTo) {
    const redirectToUrl = new URL(redirectTo);
    path = redirectToUrl.pathname;
  }

  return path;
}

export default {
  ignoredReturnToUrls,
  createRedirectTo,
  getRedirectToFromURL,
  getRedirectToPathFromURL,
};
