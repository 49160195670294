/**
 *
 * GlobalStyles
 *
 * Provides global overrides to MUI Component default theming.
 */

import React from 'react';
import { withStyles } from '@material-ui/core/styles';

const overrides = globalTheme => ({
  '@global': {
    'html, body': {
      minHeight: '100vh',
      minWidth: '100vw',
      margin: 0,
      padding: 0,
    },
    'input, textarea': {
      caretColor: globalTheme.palette.survata.interface.blue2,
    },
    ':focus': {
      outline: 'none',
    },
    body: {
      fontFamily: ['"Helvetica Neue"', 'Helvetica', 'Arial', 'sans-serif'],
      '&.fontLoaded': {
        fontFamily: [
          'Lato',
          '"Helvetica Neue"',
          'Helvetica',
          'Arial',
          'sans-serif',
        ],
      },
      // nest this to improve specificity
      '& .MuiTooltip-tooltip': {
        color: globalTheme.palette.text.primary,
        backgroundColor: globalTheme.palette.common.white,
        boxShadow: globalTheme.shadows[2],
      },
      '& .MuiTooltip-arrow': {
        color: globalTheme.palette.common.white,
      },
      '& .MuiButton-contained': {
        boxShadow: 'none',
        borderRadius: 4,
      },
      '& .MuiButton-containedPrimary': {
        '&:hover': {
          backgroundColor: globalTheme.palette.survata.interface.blue2,
        },
      },
      // Override the underline styling for text fields
      '& .MuiInput-underline': {
        '&::before': {
          borderBottom: `2px solid ${globalTheme.palette.survata.interface.bluegrey}`,
        },
        '&:hover:not(.Mui-disabled):before': {
          borderBottom: `2px solid ${globalTheme.palette.survata.interface.blue2}`,
        },
      },
      // Override the outlined text fields styling
      '& .MuiOutlinedInput-root': {
        '&:hover .MuiOutlinedInput-notchedOutline': {
          borderColor: globalTheme.palette.survata.interface.blue2,
        },
      },
    },
    '#app': {
      backgroundColor: globalTheme.palette.survata.indicators.grey,
      height: '100vh',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'stretch',
    },
  },
});

/**
 * Empty component to bind our styles to.
 *
 * See https://material-ui.com/customization/components/#global-css-override for details.
 *
 * @returns {React.Component}
 */
function GlobalStyles() {
  return null;
}

GlobalStyles.propTypes = {};

export default withStyles(overrides)(GlobalStyles);
