/**
 * InviteUserToTeam
 */

import React from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { IconButton, Button, TextField, Typography } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/DeleteForever';
import { Warning } from '@material-ui/icons';
import PropTypes from 'prop-types';

import Modal from 'components/Modal';
import {
  Table,
  TableContainer,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
} from 'components/Tables';
import messages from './messages';
import { useStyles } from './styled';
import { useInviteUserToTeam } from './useIinviteUsersToTeamHook';

/**
 * @typedef Team
 * @property {string} teamId
 * @property {string} teamName
 */

/**
 * InviteUserToTeam
 *
 * @param {object} props
 * @param {Team} props.team
 * @param {Function} props.onClose
 * @returns {React.Component}
 */
function InviteUserToTeam({ team, onClose }) {
  const intl = useIntl();
  const classes = useStyles();

  const {
    firstName,
    setFirstName,
    lastName,
    setLastName,
    email,
    setEmail,
    editing,
    users,
    errors,
    submitting,
    emptyUsers,
    userCanBeAddedToTeam,
    addButtonLabel,
    //
    addUser,
    editUser,
    cancelEditUser,
    deleteUser,
    onSubmit,
  } = useInviteUserToTeam(team, onClose);

  return (
    <Modal
      open
      title={intl.formatMessage(messages.title, { teamName: team.teamName })}
      primaryButtonLabel={intl.formatMessage(messages.send)}
      secondaryButtonLabel={intl.formatMessage(messages.cancel)}
      onPrimaryClick={onSubmit}
      onSecondaryClick={onClose}
      primaryProps={{
        variant: 'contained',
        color: 'primary',
        loading: submitting,
        disabled: users.length === 0,
        size: 'md',
      }}
      secondaryProps={{
        disabled: submitting,
        size: 'md',
      }}
      className={classes.inviteUserModal}
      actionsNotification={
        errors.generalError ? (
          <Typography className={classes.error}>
            <Warning size={14} color="secondary" /> {errors.generalError}
          </Typography>
        ) : null
      }
      maxWidth="md"
    >
      <div className={classes.userForm}>
        <TextField
          id="firstName"
          label={intl.formatMessage(messages.firstName)}
          helperText={intl.formatMessage(messages.required)}
          InputLabelProps={{ shrink: true }}
          value={firstName}
          placeholder={intl.formatMessage(messages.firstNamePlaceholder)}
          onChange={event => setFirstName(event.target.value)}
          disabled={submitting}
          fullWidth
        />
        <TextField
          id="lastName"
          label={intl.formatMessage(messages.lastName)}
          helperText={intl.formatMessage(messages.required)}
          InputLabelProps={{ shrink: true }}
          value={lastName}
          placeholder={intl.formatMessage(messages.lastNamePlaceholder)}
          onChange={event => setLastName(event.target.value)}
          disabled={submitting}
          fullWidth
        />
        <TextField
          id="email"
          label={intl.formatMessage(messages.emailAddress)}
          helperText={intl.formatMessage(messages.required)}
          InputLabelProps={{ shrink: true }}
          type="email"
          value={email}
          placeholder={intl.formatMessage(messages.emailAddressPlaceholder)}
          onChange={event => setEmail(event.target.value)}
          disabled={submitting}
          fullWidth
        />
      </div>
      <div className={classes.userFormActions}>
        {editing && (
          <Button onClick={cancelEditUser}>
            <FormattedMessage {...messages.cancel} />
          </Button>
        )}
        <Button
          onClick={addUser}
          variant="contained"
          color="primary"
          disabled={!userCanBeAddedToTeam}
        >
          <FormattedMessage {...addButtonLabel} />
        </Button>
      </div>
      {users.length === 0 ? (
        <div className={classes.tablePlaceholder}>
          <Typography component="p">
            <FormattedMessage {...messages.placeholderLineOne} />
            <br />
            <FormattedMessage {...messages.placeholderLineTwo} />
          </Typography>
        </div>
      ) : (
        <TableContainer>
          <Typography component="p" className={classes.tableTitle}>
            <FormattedMessage {...messages.tableTitle} />
          </Typography>
          <Table className={classes.table} data-testId="users-to-invite-table">
            <TableHead>
              <TableRow>
                <TableCell>
                  <FormattedMessage {...messages.name} />
                </TableCell>
                <TableCell>
                  <FormattedMessage {...messages.email} />
                </TableCell>
                <TableCell className={classes.tableActions}>
                  <FormattedMessage {...messages.actions} />
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users.map(user => (
                <TableRow>
                  <TableCell className={classes.tableCell}>
                    {`${user?.firstName} ${user?.lastName}`}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {user?.email}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {user?.email && (
                      <>
                        <IconButton
                          size="small"
                          onClick={() => editUser(user)}
                          title={`Edit ${user.email}`}
                        >
                          <EditIcon />
                        </IconButton>
                        <IconButton
                          size="small"
                          onClick={() => deleteUser(user)}
                          title={`Delete ${user.email}`}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </>
                    )}
                  </TableCell>
                </TableRow>
              ))}
              {emptyUsers.map(() => (
                <TableRow>
                  <TableCell className={classes.tableCell} />
                  <TableCell className={classes.tableCell} />
                  <TableCell className={classes.tableCell} />
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Modal>
  );
}

InviteUserToTeam.propTypes = {
  team: PropTypes.shape({
    teamId: PropTypes.string.isRequired,
    teamName: PropTypes.string.isRequired,
  }).isRequired,
  onClose: PropTypes.func.isRequired,
};

export default InviteUserToTeam;
