import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Typography } from '@material-ui/core';

import messages from 'containers/CampaignCreationPage/messages';
import SectionHeading from './SectionHeading';
import { useCampaignDraftSummaryStyles } from './styled';

/**
 * Modularized visual component for displaying the extra (final) info of a campaign draft
 *
 * @param {object} props
 * @param {string} props.otherInformation
 * @returns {React.Component}
 */
function AdditionalInfo({ otherInformation }) {
  const classes = useCampaignDraftSummaryStyles();

  return (
    <>
      <SectionHeading
        message={<FormattedMessage {...messages.additionalInformation} />}
      />
      <div className={classes.textbox}>
        {otherInformation ? (
          <Typography>{otherInformation}</Typography>
        ) : (
          <Typography variant="body2" color="textSecondary">
            <FormattedMessage {...messages.noAdditionalInformation} />
          </Typography>
        )}
      </div>
    </>
  );
}

AdditionalInfo.propTypes = {
  otherInformation: PropTypes.string,
};

export default AdditionalInfo;
