/*
 * Header constants
 */

export const namespace = 'header';

export const TEAM_SWITCH_VISIBILITY = {
  '': true, // Campaigns list
  campaigns: true, // Campaigns list
  campaign: false, // Single campaign
  brands: false, // Brands list
  brand: false, // Single brand
  portfolio: true, // Portfolio context
  settings: true, // Settings page
};
