/*
 *
 * CampaignAdminReview constants
 *
 */

export const namespace = 'campaignAdminReview';

const scope = `app/${namespace}`;

export const CAMPAIGN_ADMIN_REVIEW_LOAD_REQUESTED = `${scope}/CAMPAIGN_ADMIN_REVIEW_LOAD_REQUESTED`;
export const CAMPAIGN_ADMIN_REVIEW_LOAD_SUCCEEDED = `${scope}/CAMPAIGN_ADMIN_REVIEW_LOAD_SUCCEEDED`;
export const CAMPAIGN_ADMIN_REVIEW_LOAD_FAILED = `${scope}/CAMPAIGN_ADMIN_REVIEW_LOAD_FAILED`;
