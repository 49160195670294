import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(
  theme => ({
    inviteUserModal: {
      '& .MuiDialogTitle-root': {
        padding: theme.spacing(4, 3),
      },
      '& .MuiDialogContent-root': {
        marginBottom: 0,
        paddingTop: 0,
        paddingBottom: 0,

        '& > *:not(:last-child)': {
          marginBottom: theme.spacing(2),
        },

        '& .MuiFormControl-root .MuiFormHelperText-root': {
          textAlign: 'right',
        },
      },
      '& .MuiDialogActions-root': {
        padding: theme.spacing(2, 3),
      },
    },
    userForm: {
      display: 'flex',

      '& > .MuiFormControl-root:not(:first-child)': {
        marginLeft: theme.spacing(3),
      },
    },
    userFormActions: {
      marginTop: theme.spacing(2),
      display: 'flex',
      justifyContent: 'flex-end',

      '& *': {
        marginLeft: theme.spacing(1),
      },
    },
    tableTitle: {
      fontWeight: 'bold',
      marginBottom: theme.spacing(1),
    },
    table: {
      '& td': {
        padding: theme.spacing(1, 2),
        textAlign: 'left',
      },
    },
    tableActions: {
      width: theme.spacing(12),
    },
    tableCell: {
      height: theme.spacing(6),
    },
    tablePlaceholder: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
      height: 300,

      borderColor: theme.palette.table.border,
      borderStyle: 'solid',
      borderWidth: 1,

      marginTop: theme.spacing(6),
    },
    error: {
      display: 'flex',
      alignItems: 'center',

      '& svg': {
        marginRight: theme.spacing(1),
      },
    },
  }),
  { name: 'InviteUserToTeam' },
);
