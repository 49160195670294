import Immutable from 'immutable';

import { formatDateForDateInput } from 'utils/date';
import { FORM_CREATOR_TYPES, TARGET_COUNTRY_LIST } from './constants';

/**
 * Basic Campaign Information Form
 */

/**
 * Format data from CCF Form for use in the API.
 *
 * @param {object} props
 * @param {object} props.mediaPartnersByUUID
 * @param {string} props.campaignName
 * @param {string} props.startDate - form YYYY-MM-DD
 * @param {string} props.endDate - form YYYY-MM-DD
 * @param {number|string} props.totalImpressions
 * @param {object} props.targetCountry
 * @param {Immutable.OrderedSet} props.selectedMediaChannels
 * @param {Immutable.OrderedSet} props.selectedPartnersUUIDs
 * @param {Immutable.OrderedSet} props.suggestedMediaPartners
 * @param {Array<import('./constants').CampaignSubscription>} props.teamSubscriptions
 * @param {string} props.otherInformation
 *
 * @returns {object}
 */
export function transformDataForApi({
  mediaPartnersByUUID,
  campaignName,
  startDate,
  endDate,
  totalImpressions,
  targetCountry,
  selectedMediaChannels,
  selectedPartnersUUIDs,
  suggestedMediaPartners,
  teamSubscriptions,
  otherInformation,
  clientCampaignIdentifiers,
}) {
  return {
    formData: {
      basicInfo: {
        campaignName: campaignName.trim(),
        startDate,
        endDate,
      },
      mediaPlan: {
        totalImpressions,
        targetCountry: targetCountry.code,
        selectedMediaChannels: Array.from(selectedMediaChannels),
      },
      mediaPartners: {
        selectedPartners: Array.from(selectedPartnersUUIDs).map(
          mediaPartnerUuid => ({
            name: mediaPartnersByUUID[mediaPartnerUuid].name,
            uuid: mediaPartnerUuid,
          }),
        ),
        suggestedPartners: Array.from(suggestedMediaPartners),
      },
      userSubscriptions: teamSubscriptions.map(sub => ({
        userId: sub.user.userId,
        notificationTypes: sub.notificationType.map(i => i.value),
      })),
      summaryInfo: {
        otherInformation,
      },
      clientCampaignIdentifiers: clientCampaignIdentifiers
        .map(id => id.trim())
        .filter(id => !!id),
    },
  };
}

/**
 * Extracts the media plan information from the API
 *
 * @param {object} mediaPlanInfo
 * @returns {object} - see input params for transformMediaPlanDataForApi
 */
export function extractMediaPlanInfo(mediaPlanInfo) {
  const { totalImpressions, targetCountry, selectedMediaChannels } =
    mediaPlanInfo;

  return {
    totalImpressions,
    targetCountry: TARGET_COUNTRY_LIST.find(c => c.code === targetCountry),
    selectedMediaChannels: new Immutable.OrderedSet(selectedMediaChannels),
  };
}

/**
 * Extract data for CCF Form #3 from JSON object
 *
 * @param {object} mediaPartnersInfo - JSON object with relevant data
 * @returns {object} - see input params for transformMediaPartnersInfoForApi
 */
export function extractMediaPartnersInfo(mediaPartnersInfo) {
  const selectedPartners = new Immutable.OrderedSet(
    mediaPartnersInfo.selectedPartners,
  );

  const selectedMediaPartnerUUIDs = new Immutable.OrderedSet(
    mediaPartnersInfo.selectedPartners.map(
      selectedPartner => selectedPartner.uuid,
    ),
  );

  const suggestedPartners = new Immutable.OrderedSet(
    mediaPartnersInfo.suggestedPartners,
  );

  return {
    selectedMediaPartnerUUIDs,
    suggestedPartners,
    selectedPartners,
  };
}

/**
 * Extracts the data required by the CampaignCreation form from the persisted CampaignDraft, Campaign and Survey
 *
 * @param {object} apiDraftCampaign
 * @param {object} campaign
 * @param {object} survey
 * @returns {object} a plain object with all the attributes the CampaignCreationPage requires
 */
export function populateCampaignFormDataFromAPI(
  apiDraftCampaign,
  campaign,
  survey,
) {
  const draftCampaign = {
    creatorType: FORM_CREATOR_TYPES.NONE,
    campaignUuid: campaign.uuid,
    basicInfo: {
      campaignName: '',
      startDate: '',
      endDate: '',
    },
    mediaPlan: {
      totalImpressions: '',
      targetCountry: null,
      selectedMediaChannels: new Immutable.OrderedSet([]),
    },
    mediaPartners: {
      selectedMediaPartnerUUIDs: new Immutable.OrderedSet([]),
      selectedPartners: new Immutable.OrderedSet([]),
      suggestedPartners: new Immutable.OrderedSet([]),
    },
    summaryInfo: {
      otherInformation: '',
    },
    clientCampaignIdentifiers: [],
  };

  if (apiDraftCampaign && apiDraftCampaign.uuid) {
    draftCampaign.uuid = apiDraftCampaign.uuid;
    draftCampaign.basicInfo = apiDraftCampaign.formData.basicInfo;
    draftCampaign.mediaPlan = extractMediaPlanInfo(
      apiDraftCampaign.formData.mediaPlan,
    );
    if (apiDraftCampaign.formData.mediaPartners) {
      draftCampaign.creatorType = FORM_CREATOR_TYPES.AGENCY_OR_BRAND;
      draftCampaign.mediaPartners = extractMediaPartnersInfo(
        apiDraftCampaign.formData.mediaPartners,
      );
    } else {
      draftCampaign.creatorType = FORM_CREATOR_TYPES.PUBLISHER;
    }
    draftCampaign.summaryInfo = apiDraftCampaign.formData.summaryInfo;

    if (apiDraftCampaign.formData.clientCampaignIdentifiers) {
      draftCampaign.clientCampaignIdentifiers =
        apiDraftCampaign.formData.clientCampaignIdentifiers;
    }
  }
  // If the draft campaign was not found, set a dummy uuid
  else if (apiDraftCampaign) {
    draftCampaign.uuid = 'old-campaign-without-uuid';
  }

  // Override the information with the of the draft with the survey and campaign,
  // in case it was edited on insights
  if (survey.uuid) {
    draftCampaign.basicInfo.campaignName = survey.name;
  }
  if (campaign.uuid) {
    draftCampaign.basicInfo.startDate = formatDateForDateInput(
      new Date(campaign.scheduledStart),
    );
    draftCampaign.basicInfo.endDate = formatDateForDateInput(
      new Date(campaign.scheduledEnd),
    );

    if (campaign.expectedImpressionCount) {
      // If no value has being set, apply the value from the campaign
      if (draftCampaign.mediaPlan.totalImpressions === '') {
        draftCampaign.mediaPlan.totalImpressions =
          campaign.expectedImpressionCount;
      }
      // if the values are set but do not match, recalculate the totalImpressions
      else if (
        !Number.isNaN(draftCampaign.mediaPlan.totalImpressions) &&
        campaign.expectedImpressionCount !==
          draftCampaign.mediaPlan.totalImpressions
      ) {
        draftCampaign.mediaPlan.totalImpressions =
          campaign.expectedImpressionCount;
      }
    }
  }

  return draftCampaign;
}
