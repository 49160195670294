import { defineMessages } from 'react-intl';
import { generateMessageMap } from 'utils/messages';

import { namespace } from './constants';

export const scope = `app.containers.${namespace}`;

export default defineMessages(
  generateMessageMap(scope, {
    title: 'Invite User to: {teamName}',
    firstName: 'First name',
    firstNamePlaceholder: 'e.g. John',
    lastName: 'Last name',
    lastNamePlaceholder: 'e.g. Doe',
    emailAddress: 'Email address',
    emailAddressPlaceholder: 'e.g. mail-to@domain.com',
    required: 'Required',
    cancel: 'CANCEL',
    send: 'SEND',
    update: 'UPDATE',
    serverError: 'Server error. Please contact the support team.',
    success: 'Invitation successfully sent.',
    addForInvitation: 'ADD FOR INVITATION',
    tableTitle: 'The following people will be invited to join this team:',
    name: 'Name',
    email: 'Email',
    actions: 'Actions',
    placeholderLineOne: 'Selected users will be displayed here.',
    placeholderLineTwo: 'Please use the form above.',
  }),
);
