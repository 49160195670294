import { all, call, put, takeLeading, takeLatest } from 'redux-saga/effects';

import FetchService from 'utils/FetchService';
import { buildUri } from 'utils/uri';

import {
  LOAD_CURRENT_USER,
  LOAD_MEDIA_PARTNERS,
  MEDIA_PARTNERS_PER_PAGE,
} from './constants';
import {
  confounderCategoriesLoaded,
  confounderTypesLoaded,
  currentUserLoaded,
  loadMediaPartners,
  mediaPartnersLoaded,
} from './actions';

/**
 * Fetches the current user
 */
export function* loadCurrentUser() {
  const url = buildUri('auth:current-user');
  try {
    const user = yield call(FetchService.get, url);
    yield put(currentUserLoaded(user));
    if (user.id) {
      yield put(loadMediaPartners(0));
    }
  } catch (e) {
    yield put(currentUserLoaded({}));
  }
}

export function* loadConfounderTypes() {
  const url = buildUri('confounder-type');
  try {
    const confounderTypes = yield call(FetchService.get, url, {
      cache: 'default',
    });
    yield put(confounderTypesLoaded(confounderTypes));
  } catch (e) {
    // eslint-disable-next-line
    console.log(e);
  }
}

export function* loadConfounderCategories() {
  const url = buildUri('confounder-category-type');
  try {
    const confounderCategories = yield call(FetchService.get, url, {
      cache: 'default',
    });
    yield put(confounderCategoriesLoaded(confounderCategories));
  } catch (e) {
    // eslint-disable-next-line
    console.log(e);
  }
}

/**
 * Loads all the media partners from the BE
 *
 * @param {object} params
 * @param {number} params.page
 */
export function* loadMediaPartnersSaga({ page }) {
  const url = buildUri(
    'media-partner-list',
    {},
    {
      _page: page,
      _size: MEDIA_PARTNERS_PER_PAGE,
    },
  );
  try {
    const response = yield call(FetchService.get, url);
    yield put(mediaPartnersLoaded(response.content, page === 0, response.last));
    if (!response.last) {
      yield put(loadMediaPartners(page + 1));
    }
  } catch (e) {
    // TODO: throw error
    yield put(mediaPartnersLoaded([], true, true));
    // eslint-disable-next-line
    console.log(e);
  }
}

export default function* appSaga() {
  yield all([
    takeLeading(LOAD_CURRENT_USER, loadCurrentUser),
    takeLatest(LOAD_MEDIA_PARTNERS, loadMediaPartnersSaga),
    call(loadConfounderCategories),
    call(loadConfounderTypes),
  ]);
}
