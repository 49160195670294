/*
 *
 * NoticeManager constants
 *
 */

export const namespace = 'noticeManager';

const scope = `app/${namespace}`;

export const POST_MESSAGE = `${scope}/POST_MESSAGE`;
export const POP_MESSAGE = `${scope}/POP_MESSAGE`;
export const CLOSE_MESSAGE = `${scope}/CLOSE_MESSAGE`;
export const CLEAR_ALL_MESSAGES = `${scope}/CLEAR_ALL_MESSAGES`;
