import React from 'react';
import Immutable from 'immutable';

import { convertFromNode } from 'react-from-dom';

const parser = new DOMParser();

// List of HTML tags that are allowed by default when calling htmlToReact(). The
// caller can override this with a different allow list if required.
const ALLOWED_HTML_ELEMENTS = Immutable.OrderedSet.of(
  'b',
  'br',
  'em',
  'i',
  'strong',
  'u',
);

/**
 * Convert an HTML string to React components.
 *
 * @param {string} input
 * @param {Immutable.OrderedSet<string>} allowedTags
 *
 * @returns {React.Component}
 */
export function htmlToReact(input, allowedTags = ALLOWED_HTML_ELEMENTS) {
  const doc = parser.parseFromString(input, 'text/html');
  doc.body.normalize();

  // Create an action to filter out any HTML elements not in the allow list. The
  // React `convertFromNode()` function allow uses an allow list to filter out
  // undesirable attributes (such as `onload`), but permits all elements (such
  // as <script>).
  const actions = [
    {
      condition: node => {
        // Non element node types are permitted (although React `convertFromNode()`
        // will remove comments etc. itself).
        if (node.nodeType !== 1) {
          return false;
        }

        // Return false if the tag is permitted (i.e. don't apply this action)
        return !allowedTags.contains(node.nodeName.toLowerCase());
      },
      post: () => null, // deletes the node if the condition function returns true
    },
  ];

  const children = Array.from(doc.body.childNodes).map((child, index) =>
    convertFromNode(child, { index, actions }),
  );

  if (children.length === 1) {
    return children[0];
  }

  return React.createElement(React.Fragment, null, ...children);
}

/**
 * Strips HTML from a string.
 *
 * @param {string} text
 *
 * @returns {string}
 */
export function stringRemoveHtml(text) {
  const doc = parser.parseFromString(text, 'text/html');
  return doc.body.textContent;
}
