/**
 *
 * CampaignDraftSummary
 *
 */

import React from 'react';
import PropTypes from 'prop-types';

import { extractMediaPartnersInfo } from 'containers/CampaignCreationPage/data-transform-services';
import LoadingIndicator from 'components/LoadingIndicator';

import BasicCampaignInfoSummary from './BasicCampaignInfoSummary';
import AdditionalInfo from './AdditionalInfo';
import MediaPartnersSummary from './MediaPartnersSummary';
import MediaPlanSummary from './MediaPlanSummary';

/**
 * Component for displaying the information of a campaign draft.
 *
 * @param {object} props
 * @param {object} props.campaignDraft
 * @param {boolean} [props.hideAdditionalInfo] - do not display the summary info
 * @returns {React.Component}
 */
function CampaignDraftSummary({ campaignDraft, hideAdditionalInfo = false }) {
  // Prevent page from erroring as draftCampaign needs to be loaded (and defaults to {})
  if (!Object.keys(campaignDraft).length) {
    return <LoadingIndicator />;
  }

  const { basicInfo, mediaPlan, mediaPartners, summaryInfo } = campaignDraft;

  const { campaignName, startDate, endDate } = basicInfo || {};
  const { totalImpressions, targetCountry, selectedMediaChannels } =
    mediaPlan || {};
  const { suggestedPartners, selectedPartners } = extractMediaPartnersInfo(
    mediaPartners || {},
  );
  const { otherInformation } = summaryInfo || {};

  return (
    <>
      <BasicCampaignInfoSummary
        name={campaignName}
        startDate={startDate}
        endDate={endDate}
      />
      <MediaPlanSummary
        totalImpressions={totalImpressions || 0}
        targetCountry={targetCountry}
        mediaChannels={selectedMediaChannels}
      />
      <MediaPartnersSummary
        selectedPartners={selectedPartners}
        suggestedPartners={suggestedPartners}
      />
      {!hideAdditionalInfo && (
        <AdditionalInfo otherInformation={otherInformation} />
      )}
    </>
  );
}

CampaignDraftSummary.propTypes = {
  campaignDraft: PropTypes.object.isRequired,
  hideAdditionalInfo: PropTypes.bool,
};

export default CampaignDraftSummary;
