import { all, call, put, takeLatest } from 'redux-saga/effects';

import FetchService from 'utils/FetchService';
import { buildUri } from 'utils/uri';

import * as actions from './actions';
import { CAMPAIGN_ADMIN_REVIEW_LOAD_REQUESTED } from './constants';

/**
 * Retrieve draft campaign admin data.
 *
 * @param {object} action - The action that is triggering this saga function
 * @param {string} action.draftUuid - Uuid of the draft campaign entity to load
 */
export function* loadCampaignAdminReviewData({ draftUuid }) {
  const draftCampaignUrl = buildUri('campaign-creator:draft-campaign-detail', {
    campaignDraftUuid: draftUuid,
  });
  try {
    const draftCampaign = yield call(FetchService.get, draftCampaignUrl);
    yield put(actions.campaignAdminReviewLoadSucceeded(draftCampaign));
  } catch (e) {
    yield put(actions.campaignAdminReviewLoadFailed(e));
  }
}

/**
 * Root saga manages watcher lifecycle.
 */
export default function* campaignAdminReviewSaga() {
  yield all([
    takeLatest(
      CAMPAIGN_ADMIN_REVIEW_LOAD_REQUESTED,
      loadCampaignAdminReviewData,
    ),
  ]);
}
