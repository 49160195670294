import { escapeRe } from 'utils/string';

/**
 * Sort an array by the specified field.
 *
 * @param {string}     fieldName - Name of array field to sort by
 * @param {boolean}    [reverse = false] - Reverse sort order
 *
 * @returns {Function} Comparison function using the specified field name
 */
export function sortByField(fieldName, reverse = false) {
  return (a, b) => {
    if (!Object.prototype.hasOwnProperty.call(a, fieldName)) {
      return 0;
    }

    const type = typeof a[fieldName];

    const aSortValue =
      type === 'string' ? a[fieldName].toUpperCase() : a[fieldName];
    const bSortValue =
      type === 'string' ? b[fieldName].toUpperCase() : b[fieldName];

    if (aSortValue > bSortValue) {
      return reverse ? -1 : 1;
    }
    if (aSortValue < bSortValue) {
      return reverse ? 1 : -1;
    }
    return 0;
  };
}

/**
 * Combines multiple comparison functions together.
 *
 * @param {Function[]} sorters - the comparison function
 * @returns {Function} the combined comparison function
 */
export function combineSorters(...sorters) {
  return (a, b) => {
    for (let i = 0; i < sorters.length; ++i) {
      const result = sorters[i](a, b);
      if (result !== 0) {
        return result;
      }
    }

    return 0;
  };
}

/**
 * Get a range array.
 *
 * Ex: range(5) -> [0,1,2,3,4]   range(4,1) -> [1,2,3,4]
 *
 * @param {number}     length - Length of array
 * @param {number}     [start] - Value to begin range
 *
 * @returns {number[]} Array of sequential integers, starting at "start" (or 0 by default)
 */
export function range(length, start = 0) {
  return Array.from({ length }, (x, i) => i + start);
}

/**
 * Generate an array with the specified length and the specified value.
 *
 * Ex: initializeArray(2, '') -> ['', '']
 *
 * @param {number}     length - Length of array
 * @param {*}     [value = null] - Value to insert on each position of the array
 *
 * @returns {*[]} Array with the given length where each position will have the given value
 */
export function initializeArray(length, value) {
  return Array.from({ length }, (x, i) => {
    if (typeof value === 'function') {
      return value(i);
    }
    return value;
  });
}

/**
 * Fills an array with the specified length and the specified value.
 *
 * Ex: fillArray([''], 3, '') -> ['', '', '']
 *
 * @param {Array}      array - The array to be filled
 * @param {number}     length - Length of array
 * @param {*}     [value = null] - Value to insert on each position of the array
 *
 * @returns {*[]} Array with the given length where each position will have the given value
 */
export function fillArray(array, length, value) {
  if (!array) {
    return initializeArray(length, value);
  }
  const result = [...array];
  while (result.length < length) {
    if (typeof value === 'function') {
      result.push(value(result.length));
    } else {
      result.push(value);
    }
  }
  return result;
}

/**
 * Filters an array for items which match a given search term.
 *
 * If items is an array of objects, field specifies the key to match on.
 *
 * @param {Array} items
 * @param {string} term
 * @param {string} [field]
 *
 * @returns {object[]}
 */
export function filterBySearchTerm(items, term, field) {
  const result = [];
  let tempNestedList;
  let tempParentItem;

  if (!term) {
    return items;
  }
  const filterRe = new RegExp(escapeRe(term), 'i');

  items.forEach(item => {
    if (item.nested && item.nested.length > 0) {
      tempNestedList = item.nested.filter(nested =>
        filterRe.test(field ? nested[field] : nested),
      );
      if (tempNestedList && tempNestedList.length > 0) {
        // Don't mutate the original item with the sorted nested list,
        // create a shallow copy here instead
        tempParentItem = { ...item };
        tempParentItem.nested = tempNestedList;
        result.push(tempParentItem);
      }
    } else if (filterRe.test(field ? item[field] : item)) {
      result.push(item);
    }
  });
  return result;
}

/**
 * Groups a list of objects by a given field.
 *
 * @param {object[]} records
 * @param {string} fieldName
 *
 * @returns {object}
 */
export function groupByField(records, fieldName) {
  return records.reduce((acc, record) => {
    const key = record[fieldName];
    if (key in acc) {
      acc[key].push(record);
    } else {
      acc[key] = [record];
    }
    return acc;
  }, {});
}

/**
 * Sort by Array Value
 * sortByArray({sortedArray, listToSort, sortKey, keyTrunctor})
 *
 * @param {object} props
 * @param {string[]} props.sortedArray
 * @param {object[]} props.listToSort
 * @param {string} props.sortKey
 * @param {string} props.keyTruncator
 *
 * @returns {object[]}
 *
 * Groups the objects in `listToSort` by the value of their `sortKey`, truncating at `keyTruncator` (if present).
 * Items starting with the same key before the `keyTruncator` are grouped together.
 * Returns an array of all the objects in all the groups, in order of the keys in `sortedArray`.
 *
 * Ex:
 * sortedArray = ["delta","beta","charlie"]
 * listToSort = [{code:"charlie_3"},{code:"beta_1"},{code:"delta_8"},{code:"charlie_2"}]
 * sortKey = "code"
 * keyTruncator = "_"
 *
 * sortByArray(sortedArray, listToSort, sortKey, keyTruncator)
 * -> [{code:"delta_8"},{code:"beta_1"},{code:"charlie_3"},{code:"charlie_2"}]
 *
 */
export function sortByArray({
  sortedArray,
  listToSort,
  sortKey,
  keyTruncator,
}) {
  // Setup the final, outgoing list and the grouping object.
  let sortedList = [];
  const groupedItems = {};
  // Iterate through original list, grouping into grouping object by truncated key.
  listToSort.forEach(oneItemToGroup => {
    const truncateAt = new RegExp(`${keyTruncator}.*$`);
    const thisGroup = keyTruncator
      ? oneItemToGroup[sortKey].replace(truncateAt, '')
      : oneItemToGroup[sortKey];
    groupedItems[thisGroup] = groupedItems[thisGroup] || [];
    groupedItems[thisGroup].push(oneItemToGroup);
  });
  // Iterate through sorting array, pushing all objects in each group into the final array.
  sortedArray.forEach(oneGroup => {
    const oneGroupItems = groupedItems[oneGroup] || [];
    sortedList = [...sortedList, ...oneGroupItems];
  });
  // Return the sorted, grouped list.
  return sortedList;
}

/**
 * Slices an array by size into multiple arrays.
 *
 * @param {object[]} array
 * @param {number} size
 * @returns {object[][]}
 */
export function sliceBySize(array, size) {
  const result = [];
  for (let i = 0, l = array.length; i < l; i += size) {
    result.push(array.slice(i, i + size));
  }
  return result;
}
