import React from 'react';
import hoistNonReactStatics from 'hoist-non-react-statics';
import { ReactReduxContext } from 'react-redux';

import getInjectors from './reducerInjectors';

/**
 * @callback reducerCallback
 * @param {object} state - Redux state
 * @param {object} action - Redux action
 */

/**
 * Dynamically injects a reducer
 *
 * @param {object} props
 * @param {string} props.key - A key of the reducer
 * @param {reducerCallback} props.reducer - A reducer that will be injected
 *
 * @returns {React.Component}
 */
export default ({ key, reducer }) =>
  WrappedComponent => {
    class ReducerInjector extends React.Component {
      static WrappedComponent = WrappedComponent;

      constructor(props, context) {
        super(props, context);

        getInjectors(context.store).injectReducer(key, reducer);
      }

      render() {
        return <WrappedComponent {...this.props} />;
      }
    }
    ReducerInjector.contextType = ReactReduxContext;
    ReducerInjector.displayName = `withReducer(${
      WrappedComponent.displayName || WrappedComponent.name || 'Component'
    })`;

    return hoistNonReactStatics(ReducerInjector, WrappedComponent);
  };

const useInjectReducer = ({ key, reducer }) => {
  const context = React.useContext(ReactReduxContext);
  React.useEffect(() => {
    getInjectors(context.store).injectReducer(key, reducer);

    // Deliberately only run once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export { useInjectReducer };
