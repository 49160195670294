import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { useStyles } from './styled';
import { TableContext } from './context';

/**
 * Custom wrapper for MUI TableBody
 *
 * @param {object} props
 * @param {string} [props.className]
 *
 * @returns {React.Component}
 */
function TableBody({ className, ...props }) {
  const classes = useStyles();

  return (
    <TableContext.Provider value="body">
      <tbody className={clsx(classes.tbody, className)} {...props} />
    </TableContext.Provider>
  );
}

TableBody.propTypes = {
  className: PropTypes.string,
};

export default TableBody;
