import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { useIntl } from 'react-intl';
import { createStructuredSelector } from 'reselect';

import injectReducer from 'utils/injectReducer';
import injectSaga from 'utils/injectSaga';
import { analytics } from 'utils/googleTagManagerInitializer';
import LoadingIndicator from 'components/LoadingIndicator';
import AppContent from 'containers/AppContent';
import NoticeManager from 'containers/NoticeManager';

import messages from './messages';
import reducer from './reducer';
import saga from './saga';
import { loadCurrentUser } from './actions';
import { selectCurrentUserLoading } from './selectors';

/**
 * root App component.
 *
 * @param {object} props
 * @param {boolean} [props.loading]
 * @param {Function} props.onMount
 *
 * @returns {React.Component}
 */
export function App({ loading, onMount }) {
  const intl = useIntl();

  /**
   * Load current user when component mounts
   */
  React.useEffect(
    () => {
      onMount();
      analytics(window, document, 'script', 'dataLayer', 'GTM-W6B955B');
    },
    /* eslint-disable-next-line */
    []);

  if (loading) {
    return <LoadingIndicator />;
  }

  return (
    <>
      <Helmet titleTemplate=" %s | Upwave" defaultTitle="Upwave">
        <meta
          name="description"
          content={intl.formatMessage(messages.metaDescription)}
        />
      </Helmet>
      <NoticeManager />
      <AppContent />
    </>
  );
}

App.propTypes = {
  loading: PropTypes.bool,
  onMount: PropTypes.func.isRequired,
};

const mapStateToProps = createStructuredSelector({
  loading: selectCurrentUserLoading,
});

const mapDispatchToProps = dispatch => ({
  onMount: () => dispatch(loadCurrentUser()),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

const withReducer = injectReducer({ key: 'app', reducer });
const withSaga = injectSaga({ key: 'app', saga });

export default compose(withReducer, withSaga, withConnect)(App);
