import React from 'react';
import PropTypes from 'prop-types';

import { Table, TableBody, TableContainer } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';

import { formatDayForLocalTimeZone } from 'utils/date';
import Formatter from 'components/Formatter';
import messages from 'containers/CampaignCreationPage/messages';
import DoubleCellRow from './DoubleCellRow';
import SectionHeading from './SectionHeading';
import { useCampaignDraftSummaryStyles } from './styled';

/**
 * Modularized visual component for displaying the basic info of a campaign draft
 * (associated directly with BasicCampaignInformationForm)
 *
 * @param {object} props
 * @param {string} props.name
 * @param {string} props.startDate
 * @param {string} props.endDate
 * @returns {React.Component}
 */
export default function BasicCampaignInfoSummary({ name, startDate, endDate }) {
  const classes = useCampaignDraftSummaryStyles();
  return (
    <>
      <SectionHeading message={<FormattedMessage {...messages.general} />} />
      <TableContainer>
        <Table className={classes.table}>
          <TableBody>
            <DoubleCellRow
              metadata={<FormattedMessage {...messages.campaignTitle} />}
              value={name}
            />
            <DoubleCellRow
              metadata={<FormattedMessage {...messages.campaignDateRange} />}
              value={
                <Formatter
                  type="daterange"
                  value={{
                    startDate: formatDayForLocalTimeZone(startDate),
                    endDate: formatDayForLocalTimeZone(endDate),
                  }}
                />
              }
            />
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

BasicCampaignInfoSummary.propTypes = {
  name: PropTypes.string.isRequired,
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
};
