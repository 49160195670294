/**
 *
 * Admin
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Route, Switch } from 'react-router-dom';

import CampaignAdminReview from 'containers/CampaignAdminReviewV2';

import AdminDefault from './AdminDefault';

/**
 * Admin interface Router
 *
 * @param {object} props
 * @param {object} props.match - match property from react-router
 *
 * @returns {React.Component}
 */
function Admin({ match }) {
  return (
    <Switch>
      <Route
        path={`${match.url}/campaign/:uuid`}
        component={CampaignAdminReview}
      />
      <Route component={AdminDefault} />
    </Switch>
  );
}

Admin.propTypes = {
  match: PropTypes.object.isRequired,
};

export default Admin;
