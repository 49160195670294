import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';

const TeamSwitchContext = React.createContext([() => {}, () => {}]);

/**
 * Team switcher context provider
 *
 * @param {object} props
 * @param {object} props.children
 *
 * @returns {React.Component}
 */
export function TeamSwitchContextProvider({ children }) {
  const state = useState(() => () => {});

  return (
    <TeamSwitchContext.Provider value={state}>
      {children}
    </TeamSwitchContext.Provider>
  );
}

TeamSwitchContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useTeamSwitch = () => useContext(TeamSwitchContext);
export default TeamSwitchContextProvider;
