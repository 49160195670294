export const namespace = 'AppContent';

export const APP_CONTEXTS = {
  CAMPAIGNS: 'CAMPAIGNS',
  PORTFOLIO: 'PORTFOLIO',
  BRANDS: 'BRANDS',
};

export const ALL_UPWAVE_DEMOS_TEAM_ID = 'g:-OhrognteRCmIgucpQhkJD-742w';
export const PROD_UPWAVE_DEMOS_TEAM_ID = 'g:zxkPJPIbM1BwXZ4VUugL9eT3T0I';
