/**
 * app.js
 *
 * This is the entry file for the application, only setup and boilerplate
 * code.
 */

// Import all the third party stuff
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router/immutable';
import FontFaceObserver from 'fontfaceobserver';
import { CssBaseline } from '@material-ui/core';
import { MuiThemeProvider } from '@material-ui/core/styles';
import 'sanitize.css/sanitize.css';

import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

// Import cache busting file
import { resetCacheForUpdate } from 'utils/browserCacheBust';

// Import root app
import App from 'containers/App';
import GlobalStyles from 'components/GlobalStyles';

// Import Language Provider
import LanguageProvider from 'containers/LanguageProvider';
import history from './utils/history';
import theme from './utils/theme';

// Include the favicon
import './images/favicon.ico';
import './images/favicon.svg';

// Include robots.txt
import './robots.txt';

import configureStore from './configureStore';

// Import i18n messages
import { translationMessages } from './i18n';

Sentry.init({
  dsn: window.$config.keys.sentry,
  environment: window.$config.target,
  integrations: [new Integrations.BrowserTracing()],
});

// Preforms the clearing of client side browser cache so all browsers
// will have the same client side code
resetCacheForUpdate();

// Observe loading of Lato (to remove lato, remove the <link> tag in
// the index.html file and this observer)
const latoObserver = new FontFaceObserver('Lato', {});

// When Lato is loaded, add a font-family using Lato to the body
latoObserver.load().then(() => {
  document.body.classList.add('fontLoaded');
});

// Create redux store with history
const initialState = {};
const store = configureStore(initialState, history);
const MOUNT_NODE = document.getElementById('app');

const render = messages => {
  ReactDOM.render(
    <Provider store={store}>
      <LanguageProvider messages={messages}>
        <ConnectedRouter history={history}>
          <MuiThemeProvider theme={theme}>
            <CssBaseline />
            <GlobalStyles />
            <App />
          </MuiThemeProvider>
        </ConnectedRouter>
      </LanguageProvider>
    </Provider>,
    MOUNT_NODE,
  );
};

if (module.hot) {
  // Hot reloadable React components and translation json files
  // modules.hot.accept does not accept dynamic dependencies,
  // have to be constants at compile-time
  module.hot.accept(['./config', './i18n', 'containers/App'], () => {
    ReactDOM.unmountComponentAtNode(MOUNT_NODE);
    render(translationMessages);
  });
}

// Kick off the application
render(translationMessages);

// Pull in our dummy service worker to uninstall our old service worker.
// The filename is renamed by webpack to .js ; it's .txt to prevent webpack inlining the code here.
import './sw.txt';
