/**
 *
 * NumberFormatter
 *
 */

import React from 'react';
import PropTypes from 'prop-types';

import { FormattedNumber } from 'react-intl';

/**
 * Format numbers.
 *
 * @param {object} props
 * @param {number} props.value
 * @param {number} [props.maximumFractionDigits=1]
 *
 * @returns {string}
 */
function NumberFormatter({ value, maximumFractionDigits = 1, ...rest }) {
  return (
    <FormattedNumber
      value={value}
      maximumFractionDigits={maximumFractionDigits}
      {...rest}
    />
  );
}

NumberFormatter.propTypes = {
  value: PropTypes.number,
  maximumFractionDigits: PropTypes.number,
};

export default NumberFormatter;
