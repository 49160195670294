import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import TableRow from './TableRow';

import { TableContext } from './context';
import { useStyles } from './styled';

/**
 * A table body group header row.
 *
 * @param {object} props
 * @param {string} [props.className]
 *
 * @returns {React.Component}
 */
function TableGroupHeader({ className, secondary, ...props }) {
  const classes = useStyles();

  return (
    <TableContext.Provider value="head">
      <TableRow
        className={clsx(
          classes.groupHeader,
          { [classes.secondary]: secondary },
          className,
        )}
        {...props}
      />
    </TableContext.Provider>
  );
}

TableGroupHeader.propTypes = {
  className: PropTypes.string,
  secondary: PropTypes.bool,
};

export default TableGroupHeader;
