import { defineMessages } from 'react-intl';
import { generateMessageMap } from 'utils/messages';

export const scope = 'app.components.BillingReminder';

export default defineMessages(
  generateMessageMap(scope, {
    billingReminderModalTitle: 'Notice of Overdue Payment',
    billingReminderModalContent:
      'Your account is in arrears and you are at risk of losing Upwave login access.\n' +
      'Arrange for payment by contacting payables@upwave.com.',
    billingReminderCloseButton: 'Dismiss',
  }),
);
