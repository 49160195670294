import { makeStyles } from '@material-ui/core/styles';

export const useCampaignDraftSummaryStyles = makeStyles(
  baseTheme => ({
    boldHeader: {
      fontWeight: baseTheme.typography.fontWeightBold,
    },
    table: {
      border: [[1, 'solid', baseTheme.palette.survata.table.tableBorder]],
      // For all cells
      '& th, & td': {
        border: [[1, 'solid', baseTheme.palette.survata.table.tableBorder]],
        padding: [[baseTheme.spacing(1), baseTheme.spacing(2)]],
      },
      '& tbody': {
        '& th': {
          backgroundColor: baseTheme.palette.survata.table.subheaderBackground,
          '& .MuiTypography-root': {
            fontWeight: baseTheme.typography.fontWeightBold,
          },
        },
      },
      '& thead': {
        '& th': {
          backgroundColor: baseTheme.palette.survata.table.headerBackground,
          '& .MuiTypography-root': {
            fontWeight: baseTheme.typography.fontWeightBold,
          },
        },
      },
    },
    spacer: {
      padding: baseTheme.spacing(1),
    },
    textbox: {
      border: [[1, 'solid', baseTheme.palette.survata.table.tableBorder]],
      padding: [[baseTheme.spacing(1), baseTheme.spacing(2)]],
    },
  }),
  { name: 'CampaignDraftSummary' },
);

export const useSectionHeadingStyles = makeStyles(
  baseTheme => ({
    boldHeader: {
      fontWeight: baseTheme.typography.fontWeightBold,
    },
  }),
  { name: 'SectionHeading' },
);

export const useDoubleCellRowStyles = makeStyles(
  {
    metadataCell: {
      width: 300,
    },
  },
  { name: 'DoubleCellRow' },
);
