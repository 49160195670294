/*
 * CampaignAdminReview Messages
 *
 * This contains all the text for the CampaignAdminReview container.
 */

import { defineMessages } from 'react-intl';

import { namespace } from './constants';

export const scope = `app.containers.${namespace}`;

export default defineMessages({
  header: {
    id: `${scope}.header`,
    defaultMessage: 'New campaign administration',
  },
  campaignInformation: {
    id: `${scope}.campaignInformation`,
    defaultMessage: 'Campaign information',
  },

  accountEmail: {
    id: `${scope}.accountEmail`,
    defaultMessage: 'Account Email:',
  },
  creationDate: {
    id: `${scope}.creationDate`,
    defaultMessage: 'Creation Date:',
  },
  submissionDate: {
    id: `${scope}.submissionDate`,
    defaultMessage: 'Submission Date:',
  },
  notAvailable: {
    id: `${scope}.notAvailable`,
    defaultMessage: 'N/A',
  },
  approvalQuestion: {
    id: `${scope}.approvalQuestion`,
    defaultMessage: 'Does this campaign pass feasibility?',
  },
  approvalConsiderations: {
    id: `${scope}.approvalConsiderations`,
    defaultMessage:
      'Answering «Yes» will create a campaign in our system. This cannot be reversed.',
  },
  approveAction: {
    id: `${scope}.approveAction`,
    defaultMessage: 'Yes',
  },
  rejectAction: {
    id: `${scope}.rejectAction`,
    defaultMessage: 'No',
  },
  accessGroups: {
    id: `${scope}.accessGroups`,
    defaultMessage: 'Access Groups',
  },
  approveRequirement: {
    id: `${scope}.approveRequirement`,
    defaultMessage: 'Please select an access group to approve the campaign.',
  },
  updateToMeasurableAction: {
    id: `${scope}.updateToMeasurableAction`,
    defaultMessage: 'Update to measurable',
  },
  markedAsNotMeasurable: {
    id: `${scope}.markedAsNotMeasurable`,
    defaultMessage: 'This campaign has been marked as',
  },
  markedAsNotMeasurableDescription: {
    id: `${scope}.markedAsNotMeasurableDescription`,
    defaultMessage:
      'This will be reflected to the user in the campaign card next time they log in.',
  },
  resetStatusQuestion: {
    id: `${scope}.resetStatusQuestion`,
    defaultMessage: 'Are you sure you want to update this campaign?',
  },
  resetStatusDescription: {
    id: `${scope}.resetStatusDescription`,
    defaultMessage:
      'Marking the campaign as measurable will notify the user and automatically create a campaign in our system.',
  },
  resetStatusAnswerConfirm: {
    id: `${scope}.resetStatusAnswerConfirm`,
    defaultMessage: 'Update to measurable',
  },
  resetStatusAnswerCancel: {
    id: `${scope}.resetStatusAnswerCancel`,
    defaultMessage: 'Cancel',
  },
});
