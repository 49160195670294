import React from 'react';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import {
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';

/**
 * Alert dialog.
 *
 * @param {object}  props
 * @param {boolean} props.open
 * @param {object}  props.title
 * @param {object} props.content
 * @param {object} [props.confirmButtonMessage]
 * @param {object} props.closeButtonMessage
 * @param {Function} [props.onConfirmClicked]
 * @param {Function} props.onCloseClicked
 *
 * @returns {React.Component}
 */
function AlertDialog({
  open,
  title,
  content,
  confirmButtonMessage,
  closeButtonMessage,
  // Callbacks
  onConfirmClicked,
  onCloseClicked,
}) {
  const hasConfirmButton = confirmButtonMessage && onConfirmClicked;

  return (
    <Dialog
      open={open}
      onClose={onCloseClicked}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description" component="span">
          {content}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onCloseClicked}
          color={hasConfirmButton ? 'default' : 'primary'}
          autoFocus={!hasConfirmButton}
        >
          {closeButtonMessage}
        </Button>
        {hasConfirmButton && (
          <Button onClick={onConfirmClicked} color="primary" autoFocus>
            {confirmButtonMessage}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}

AlertDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  title: PropTypes.object.isRequired,
  content: PropTypes.object.isRequired,
  confirmButtonMessage: PropTypes.object,
  closeButtonMessage: PropTypes.object.isRequired,
  // Callbacks
  onConfirmClicked: PropTypes.func,
  onCloseClicked: PropTypes.func.isRequired,
};

export default AlertDialog;
