import { fromJS } from 'immutable';

import { namespace } from './constants';

const scope = `app/containers/${namespace}`;

export const CAMPAIGNS_LOADED = `${scope}/CAMPAIGN_LOADED`;
export const TEAMS_LOADED = `${scope}/TEAMS_LOADED`;
export const CURRENT_TEAM = `${scope}/CURRENT_TEAM`;

export const initialState = fromJS({
  campaigns: [],
  teams: [],
  currentTeam: null,
  isLoading: true,
});

export default (state = initialState, action) => {
  switch (action.type) {
    case CAMPAIGNS_LOADED:
      return state.merge({
        campaigns: fromJS(action.campaigns),
        isLoading: false,
      });
    case TEAMS_LOADED:
      // eslint-disable-next-line
      let currentTeam = action.teams.find(({ active }) => active);
      if (!currentTeam) {
        currentTeam = action.teams.length > 0 ? action.teams : {};
      }

      return state.merge({
        currentTeam: currentTeam.teamId,
        teams: fromJS(action.teams),
      });
    case CURRENT_TEAM:
      return state.merge({
        campaigns: fromJS([]),
        currentTeam: action.currentTeam.teamId,
        isLoading: true,
      });
    default:
      return state;
  }
};

/*
 * Actions
 */

/**
 * Event on campaigns loaded.
 *
 * @param {object[]} campaigns
 *
 * @returns {object}
 */
function campaignsLoaded(campaigns) {
  return {
    type: CAMPAIGNS_LOADED,
    campaigns,
  };
}

/**
 * Event on teams loaded.
 *
 * @param {object} teams
 *
 * @returns {object}
 */
function teamsLoaded(teams) {
  return {
    type: TEAMS_LOADED,
    teams,
  };
}

/**
 * Event to select the current team
 *
 * @param {object} currentTeam
 * @returns {object}
 */
function setCurrentSelectedTeam(currentTeam) {
  return {
    type: CURRENT_TEAM,
    currentTeam,
  };
}

// Handy object for passing to bindActionCreators
export const actions = {
  campaignsLoaded,
  teamsLoaded,
  setCurrentSelectedTeam,
};
