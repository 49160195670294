import React from 'react';
import createSvgIcon from '@material-ui/icons/utils/createSvgIcon';

/**
 * Portfolios Icon
 *
 */
export default createSvgIcon(
  <path
    fill="#798094"
    d="M1 7v14c0 1.1.9 2 2 2h14v-2H3V7H1zm6-4v14h14v2H7c-1.1 0-2-.9-2-2V3h2zm13-1a2 2 0 012 2v9a2 2 0 01-2 2h-9a2 2 0 01-2-2V4a2 2 0 012-2h9zm-4.463 2l-1.331 3.14-3.406.29 2.586 2.24L12.61 13l2.928-1.767L18.464 13l-.777-3.33 2.587-2.24-3.406-.29L15.537 4z"
  />,
  'Portfolios',
);
