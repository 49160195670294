const EMPTY_COMPANY_AND_ORGANIZATION_PLACEHOLDER =
  '__COMPANY_AND_ORGANIZATION_NOT_SET__';

/**
 * Helper function to remove the Top Level Domain (TLD) from a given url.
 *
 * NOTE: this function does not verify that the TLD that is removed is
 * actually a valid TLD, so you could lose information
 *
 * @param {string} url
 *
 * @returns {string}
 */
function stripURLTopLevelDomain(url) {
  return url.split('.').slice(0, -1).join('.');
}

/**
 * This does some ugly magic to make sure that we do get a company for
 * the given user.
 *
 * @todo remove this logic once
 * https://survata.atlassian.net/browse/WS-489 -- is completed
 * Presently both are needed here, since BE sometimes has values for
 * one and not the other.
 *
 * @param {object} user
 *
 * @returns {string}
 */
function getCompanyForUser(user) {
  if (user.company) {
    return user.company;
  }

  if (user.organization && user.organization.name) {
    return user.organization.name;
  }

  // We should not really get here, but this is necessary so that
  // GA does not drop rows in the usage log when we have neither
  // company or organization?.name
  if (user.organization && user.organization.domain) {
    return stripURLTopLevelDomain(user.organization.domain);
  }

  // This fallback is supposed to make sure that we do not have empty
  // organizations in our data so that our metrics do not have gaps
  // there will be GA report setup to track and help remediate this
  return EMPTY_COMPANY_AND_ORGANIZATION_PLACEHOLDER;
}

export {
  getCompanyForUser,
  stripURLTopLevelDomain,
  EMPTY_COMPANY_AND_ORGANIZATION_PLACEHOLDER,
};
