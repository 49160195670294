import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { useStyles } from './styled';

/**
 * Table Container
 *
 * @param {object} props
 * @param {string} [props.className]
 *
 * @returns {React.Component}
 */
function TableContainer({ className, ...props }) {
  const classes = useStyles();

  return <div className={clsx(classes.container, className)} {...props} />;
}

TableContainer.propTypes = {
  className: PropTypes.string,
};

export default TableContainer;
