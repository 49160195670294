import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { FormattedDate } from 'react-intl';

const styles = () => ({
  root: {
    display: 'inline',
  },
});

/* eslint-disable react/prefer-stateless-function */
class DateFormatter extends React.Component {
  render() {
    const { value, day, month, year, ...rest } = this.props;
    const formattingProps = {
      month,
      day,
      year,
    };
    if (!(month || day || year)) {
      formattingProps.month = '2-digit';
      formattingProps.day = '2-digit';
      formattingProps.year = 'numeric';
    }
    if (value) {
      return <FormattedDate value={value} {...formattingProps} {...rest} />;
    }
    return <span>&ndash;</span>;
  }
}

DateFormatter.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  day: PropTypes.string,
  month: PropTypes.string,
  year: PropTypes.string,
};

export default withStyles(styles)(DateFormatter);
