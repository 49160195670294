// This code is provided by google tag manager here: https://developers.google.com/tag-platform/tag-manager/web
export const analytics = (w, d, s, l, i) => {
  window.dataLayer = window.dataLayer || [];
  w.dataLayer.push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
  const dl = l !== 'dataLayer' ? `&l=${l}` : '';
  const scr = `https://www.googletagmanager.com/gtm.js?id=${i}${dl}`;
  /*
      To avoid Multiple installations of google tag manager detected warning
  */
  if (!scriptExists(scr)) {
    const f = d.getElementsByTagName(s)[0];
    const j = d.createElement('script');
    j.async = true;
    j.src = scr;
    f?.parentNode?.insertBefore(j, f);
  }
};
const scriptExists = url => {
  const scripts = document.getElementsByTagName('script');
  for (let i = scripts.length; i--; ) {
    if (scripts[i].src === url) return true;
  }
  return false;
};

/**
 * Function to pass events to the GA4 datalayer
 */
export function trackGA4Event(event, properties) {
  // eslint-disable-next-line prettier/prettier
  window.dataLayer.push({ 'event': event, 'event_action': properties?.action, 'event_label': properties?.label });
}
