import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import { renderDateFmt } from 'utils/date';

/**
 * Format a date Date Range.
 *
 * @param {object} props
 * @param {object} [props.value={}]
 * @param {Date|string} props.value.startDate
 * @param {Date|string} [props.value.endDate]
 * @param {object} [props.noEndDateMessage=""] - message to show if endDate is falsey
 *
 * @returns {React.Component}
 */
function DateRangeFormatter({ value = {}, noEndDateMessage = '' }) {
  const intl = useIntl();

  const intlFormat = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  };

  const formats = {
    noEnd: ['{month} {day}, {year}', ''],
    sameMonth: ['{month} {day}', '{day}, {year}'],
    sameYear: ['{month} {day}', '{month} {day}, {year}'],
    full: ['{month} {day}, {year}', '{month} {day}, {year}'],
  };

  if (!value.startDate) {
    return <span>&ndash;</span>;
  }

  // TODO: do parsing outside of DateRangeFormatter
  const startDate =
    typeof value.startDate === 'string'
      ? new Date(value.startDate)
      : value.startDate;
  const endDate =
    typeof value.endDate === 'string' ? new Date(value.endDate) : value.endDate;

  const startParts = intl.formatDateToParts(startDate, intlFormat);
  const endParts = endDate ? intl.formatDateToParts(endDate, intlFormat) : {};

  let startFormat;
  let endFormat;
  if (!endDate) {
    [startFormat, endFormat] = formats.noEnd;
  } else if (startDate.getYear() === endDate.getYear()) {
    if (startDate.getMonth() === endDate.getMonth()) {
      [startFormat, endFormat] = formats.sameMonth;
    } else {
      [startFormat, endFormat] = formats.sameYear;
    }
  } else {
    [startFormat, endFormat] = formats.full;
  }

  return (
    <>
      {renderDateFmt(startFormat, startParts)}
      &ndash;
      {value.endDate ? renderDateFmt(endFormat, endParts) : noEndDateMessage}
    </>
  );
}

DateRangeFormatter.propTypes = {
  value: PropTypes.shape({
    startDate: PropTypes.string,
    endDate: PropTypes.string,
  }).isRequired,
  noEndDateMessage: PropTypes.node,
};

export default DateRangeFormatter;
