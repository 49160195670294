/**
 *
 * CampaignAdminReview
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Helmet } from 'react-helmet';
import { useIntl } from 'react-intl';
import { createStructuredSelector } from 'reselect';

import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import injectReducer from 'utils/injectReducer';
import injectSaga from 'utils/injectSaga';

import CampaignDraftSummary from 'components/CampaignDraftSummary';
import LoadingIndicator from 'components/LoadingIndicator';

import BasicSurveyInfoSection from './BasicSurveyInfoSection';
import { Content, Header, FormWrapper } from '../CampaignCreationPage/styled';

import { campaignAdminReviewLoadRequested } from './actions';
import { namespace } from './constants';
import messages from './messages';
import reducer from './reducer';
import saga from './saga';
import { selectDraftCampaign, selectLoading } from './selectors';

const useStyles = makeStyles(theme => ({
  hr: {
    height: 1,
    width: '100%',
    border: 'none',
    backgroundColor: theme.palette.survata.table.tableBorder,
  },
}));

/**
 * Function and callback signatures
 *
 * @callback onMount
 * @param {string} draftUuid - DraftCampaign UUID
 */

/**
 * Review Draft Campaign admin component.
 *
 * @param {object} props
 * @param {object} props.match - match property from react-router
 * @param {boolean} props.loading - draft loading indicator
 * @param {object} props.draftCampaign - the draft campaign to review
 *
 * @param {Function} props.onMount - callback to execute when component is mounted
 *
 * @returns {React.Component}
 */
export function CampaignAdminReview({
  match,
  loading,
  draftCampaign,
  onMount,
}) {
  const classes = useStyles();
  const intl = useIntl();
  const title = intl.formatMessage(messages.header);
  const draftUuid = match.params.uuid;

  React.useEffect(() => {
    onMount(draftUuid);
  }, [draftUuid]);

  if (loading || !draftCampaign.uuid) {
    return <LoadingIndicator />;
  }

  return (
    <>
      <Helmet title={title} meta={[{ name: 'description', content: title }]} />

      <Content>
        <Header>
          <Typography variant="h4">{draftCampaign.name}</Typography>
        </Header>
        <FormWrapper>
          <BasicSurveyInfoSection draftCampaign={draftCampaign} />
          <hr className={classes.hr} />
          <CampaignDraftSummary campaignDraft={draftCampaign.formData} />
        </FormWrapper>
      </Content>
    </>
  );
}

CampaignAdminReview.propTypes = {
  match: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  draftCampaign: PropTypes.object.isRequired,

  // callbacks
  onMount: PropTypes.func.isRequired,
};

const mapStateToProps = createStructuredSelector({
  loading: selectLoading,
  draftCampaign: selectDraftCampaign,
});

const mapDispatchToProps = dispatch => ({
  onMount: uuid => dispatch(campaignAdminReviewLoadRequested(uuid)),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

const withReducer = injectReducer({ key: namespace, reducer });
const withSaga = injectSaga({ key: namespace, saga });

/**
 * `compose` acts as a right reducer.
 *
 * https://redux.js.org/api/compose
 *
 * It produces a function that will call each of the arguments in turn [from
 * right to left] on the result of the previous step.
 *
 * `compose(a, b, c)(x)` is equivalent to `a(b(c(x)))` but doesn't require counting parentheses.
 */
export default compose(withReducer, withSaga, withConnect)(CampaignAdminReview);
