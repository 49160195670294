/*
 *
 * CampaignAdminReview reducer
 *
 */

import { fromJS } from 'immutable';
import {
  CAMPAIGN_ADMIN_REVIEW_LOAD_SUCCEEDED,
  CAMPAIGN_ADMIN_REVIEW_LOAD_REQUESTED,
  CAMPAIGN_ADMIN_REVIEW_LOAD_FAILED,
} from './constants';

export const initialState = fromJS({
  loading: false,
  draftCampaign: {},
});

/**
 * Reducer function.
 *
 * @param {object} state - actual state
 * @param {object} action - reducer action
 *
 * @returns {object} the new state altered by action
 */
function campaignAdminReviewReducer(state = initialState, action) {
  switch (action.type) {
    case CAMPAIGN_ADMIN_REVIEW_LOAD_REQUESTED:
      return state.set('loading', true);

    case CAMPAIGN_ADMIN_REVIEW_LOAD_SUCCEEDED:
      return state.merge({
        loading: false,
        error: null,
        draftCampaign: fromJS(action.draftCampaign),
      });

    case CAMPAIGN_ADMIN_REVIEW_LOAD_FAILED:
      return state.merge({
        loading: false,
        error: fromJS(action.error),
        draftCampaign: fromJS({}),
      });

    default:
      return state;
  }
}

export default campaignAdminReviewReducer;
