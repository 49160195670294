/**
 *
 * Formatter
 *
 */
import React from 'react';
import PropTypes from 'prop-types';

import PercentFormatter from './PercentFormatter';
import NumberFormatter from './NumberFormatter';
import DateRangeFormatter from './DateRangeFormatter';
import DateFormatter from './DateFormatter';

/**
 * Formatter switch component
 *
 * @param {object} props
 * @param {string} props.type
 * @param {...} props.rest - props to pass through
 *
 * @returns {React.Component}
 */
function Formatter({ type, ...rest }) {
  switch (type) {
    case 'percent':
      return <PercentFormatter {...rest} />;
    case 'daterange':
      return <DateRangeFormatter {...rest} />;
    case 'date':
      return <DateFormatter {...rest} />;
    case 'number':
    default:
      return <NumberFormatter {...rest} />;
  }
}

Formatter.propTypes = {
  type: PropTypes.oneOf(['percent', 'daterange', 'date', 'number']),
};

export default Formatter;
