import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Typography } from '@material-ui/core';

import { formatMMDDYYYY, parseTimestamp } from 'utils/date';
import { useCampaignDraftSummaryStyles } from 'components/CampaignDraftSummary/styled';
import InfoField from './InfoField';
import messages from './messages';

/**
 * Basic survey information panel like the user that created the draft, submission date, etc.
 *
 * @param {object} props
 * @param {object} props.draftCampaign - the draft campaign to review
 *
 * @returns {React.Component}
 */
function BasicSurveyInfoSection({ draftCampaign }) {
  const classes = useCampaignDraftSummaryStyles();
  const { creatorEmail, createdAt } = draftCampaign;
  const creationDate = formatMMDDYYYY(parseTimestamp(createdAt));

  return (
    <>
      <Typography classes={{ root: classes.boldHeader }} variant="h6">
        <FormattedMessage {...messages.campaignInformation} />
      </Typography>
      <div>
        <InfoField label={messages.accountEmail} value={creatorEmail} />
        <InfoField label={messages.creationDate} value={creationDate} />
      </div>
    </>
  );
}

BasicSurveyInfoSection.propTypes = {
  draftCampaign: PropTypes.object.isRequired,
};

export default BasicSurveyInfoSection;
