import { renderFmt } from 'utils/string';
import moment from 'moment';

const timezoneRe = /([+-]\d{1,2}:\d{1,2}|Z)$/;

/**
 * Parses an ISO-8601 formatted timestamp
 *
 * Our server currently sends timestamps without any timezone.
 * Our server currently stores timestamps in UTC.
 * When a timestamp has no timezone, interpret as UTC.
 *
 * Will return `undefined`for falsey values.
 *
 * @param {string} timestamp
 *
 * @returns {(Date|undefined)}
 */
export function parseTimestamp(timestamp) {
  if (!timestamp) return undefined;

  let ts;
  if (!timestamp.match(timezoneRe)) {
    ts = `${timestamp}Z`;
  } else {
    ts = timestamp;
  }
  return new Date(ts);
}

/**
 * Wrapper around `renderFmt` that takes a list of date parts from intl.formatDateToParts.
 *
 * @param {string} fmt
 * @param {object[]} dateParts
 *
 * @returns {string}
 */
export function renderDateFmt(fmt, dateParts) {
  const parts = dateParts.reduce((acc, part) => {
    acc[part.type] = part.value;
    return acc;
  }, {});

  return renderFmt(fmt, parts);
}

/**
 * From a given UTC timestamp having 'YYYY-MM-DDTHH:mm:ss' format, extract only
 * the 'YYYY-MM-DD' part.
 *
 * @param {string} utcTimestamp
 *
 * @returns {string} The pure date part of the timestamp corresponding to 'YYYY-MM-DD'
 */
export function extractUtcDatePartOnly(utcTimestamp) {
  const datePartLength = 'YYYY-MM-DD'.length;
  if (!utcTimestamp || utcTimestamp.length < datePartLength) {
    return '';
  }

  return utcTimestamp.split('T')[0];
}

/**
 * Formats the given date for consumption by the API.
 * Note: UTC timezone is used here.
 *
 * @param {Date} date
 *
 * @returns {string} YYYY-MM-DD
 */
function formatDateForApi(date) {
  return date.toISOString().split('T')[0];
}

/**
 * Formats the given date for use in download filenames.
 * Note: the client's local timezone is used here.
 *
 * @param {Date} date
 * @returns {string} YYYY.MM.DD
 */
export function formatDateForDownload(date) {
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');

  return `${date.getFullYear()}.${month}.${day}`;
}

/**
 * Given a date range object (containing a start and end date), format the dates in the way the backend
 * is expecting them, ready for making a url param string to be used in the API call.
 * If no start/end date is provided, return an empty object.
 *
 * @param {object} props
 * @param {object} [props.startDate] - optional prop for the beginning of the date range
 * @param {object} [props.endDate] - optional prop for thee end of the date range
 *
 * @returns {object} -  The formatted url parameter string with start/end dates
 */
export function getDateRangeParams({ startDate, endDate }) {
  if (startDate && endDate) {
    return {
      startDate: formatDateForApi(startDate),
      endDate: formatDateForApi(endDate),
    };
  }
  return {};
}

/**
 * Format a date using the mm/dd/yyyy pattern
 *
 * @param {Date} date
 *
 * @returns {string} - the formatted date
 */
export function formatMMDDYYYY(date) {
  const parts = new Intl.DateTimeFormat('en', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  }).formatToParts(date);

  return renderDateFmt('{month}/{day}/{year}', parts);
}

/**
 * Format a date using the yyyy-mm-dd pattern
 *
 * @param {Date} date
 *
 * @returns {string} - the formatted date
 */
export function formatDateForDateInput(date) {
  const parts = new Intl.DateTimeFormat('en', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  }).formatToParts(date);

  return renderDateFmt('{year}-{month}-{day}', parts);
}

/**
 * Format a date using the yyyy-mm-dd HH:mm pattern
 *
 * @param {Date} date
 *
 * @returns {string} - the formatted date
 */
export function formatDateWithTime(date) {
  const parts = new Intl.DateTimeFormat('en', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    hourCycle: 'h23',
  }).formatToParts(date);

  return renderDateFmt('{year}-{month}-{day} {hour}:{minute}', parts);
}

/**
 * Given a day (in ISO format) return a string that formats it for the user's local timezone.
 *
 * @param {string} day - in form YYYY-MM-DD
 * @returns {string}
 */
export function formatDayForLocalTimeZone(day) {
  return `${day}T00:00:00`;
}

/**
 * Format a date for a downloadable report filename.
 *
 * @param {Date} date
 *
 * @returns {string}
 */
export function formatDateForReportFilename(date) {
  const parts = new Intl.DateTimeFormat('en', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  }).formatToParts(date);

  return renderDateFmt('{year}{month}{day}', parts);
}

/**
 * Format a date for user display with the timezone intended
 * if no timezone is specified, set the default to be undefined
 * (must be undefined instead of null, since the intl function will
 * set the timezone to the browser local if it is undefined, but null will make it throw an error)
 * Check the list of timezone strings: https://www.iana.org/time-zones
 *
 * @param {Date} date
 * @param {string} timeZone
 *
 * @returns {string}
 */
export function formatDisplayDate(date, timeZone = undefined) {
  return new Intl.DateTimeFormat('en', {
    dateStyle: 'full',
    timeZone,
  }).format(date);
}

/**
 * Formats an ISO8601 formatted UTC timestamp as "Wednesday, January 1, 2020".
 *
 * @param {string} isoUtcTimestamp - the ISO8601 formatted UTC timestamp
 * @param {string} [formatString='dddd, MMMM D, YYYY'] - the format string
 * @returns {string} the formatted date
 */
export function formatUtcTimestampAsLocalDate(
  isoUtcTimestamp,
  formatString = 'dddd, MMMM D, YYYY',
) {
  return moment.utc(isoUtcTimestamp).local().format(formatString);
}
