import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from './actions';

/**
 * Custom hook that helps to expose action functions of Notice Manager.
 * NOTE: This is a custom hook and needs its name to starts with 'use..'
 * See https://reactjs.org/docs/hooks-intro.html
 *
 * @returns {object} - the hook object with all its functions
 */
export function useNoticeManagerHook() {
  // Customs hooks can only be called in other custom hooks
  // See https://fb.me/react-invalid-hook-call
  const dispatch = useDispatch();

  // All the actions functions will now be accessible through the hook object
  // See https://redux.js.org/api/bindactioncreators
  return useMemo(() => bindActionCreators(actions, dispatch), [dispatch]);
}
