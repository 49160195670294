import React from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { FormattedMessage } from 'react-intl';
import { Table, TableContainer } from '@material-ui/core';

import messages from 'containers/CampaignCreationPage/messages';
import ListOfValues from './ListOfValues';
import SectionHeading from './SectionHeading';
import { useCampaignDraftSummaryStyles } from './styled';

/**
 * Modularized visual component for displaying the media partners info of a campaign draft
 * (associated directly with MediaPartnersForm)
 *
 * @param {object} props
 * @param {Immutable.OrderedSet} props.selectedPartners
 * @param {Immutable.OrderedSet} props.suggestedPartners
 * @returns {React.Component}
 */
export default function MediaPartnersSummary({
  selectedPartners,
  suggestedPartners,
}) {
  const classes = useCampaignDraftSummaryStyles();

  const selectedPartnersForDisplay = Array.from(
    selectedPartners,
    ({ uuid, name }) => ({ key: uuid, display: name }),
  );

  const suggestedPartnersForDisplay = Array.from(
    suggestedPartners,
    partner => ({ key: partner, display: partner }),
  );

  return (
    <>
      <SectionHeading
        message={<FormattedMessage {...messages.mediaPartnersTitle} />}
      />
      <TableContainer>
        <Table className={classes.table}>
          <ListOfValues
            headerText={messages.selectedPartners}
            items={selectedPartnersForDisplay}
            noneSelectedText={messages.noMediaPartnersSelected}
          />
        </Table>
        <div className={classes.spacer} />
        <Table className={classes.table}>
          <ListOfValues
            headerText={messages.suggestedPartners}
            items={suggestedPartnersForDisplay}
            noneSelectedText={messages.noMediaPartnersSuggested}
          />
        </Table>
      </TableContainer>
    </>
  );
}

MediaPartnersSummary.propTypes = {
  selectedPartners: PropTypes.instanceOf(Immutable.OrderedSet).isRequired,
  suggestedPartners: PropTypes.instanceOf(Immutable.OrderedSet).isRequired,
};
