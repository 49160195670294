/**
 * Set data in local storage
 *
 * Local storage only supports primitive data types, so to allow it
 * to store objets, we need to stringify/parse the data.
 *
 * @param {string} key
 * @param {*} data
 */
function set(key, data) {
  window.localStorage.setItem(key, JSON.stringify(data));
}

/**
 * Get data from local storage
 *
 * @param {string} key
 *
 * @returns {*} data
 */
function get(key) {
  return JSON.parse(window.localStorage.getItem(key));
}

/**
 * Clear local storage
 */
function clear() {
  window.localStorage.clear();
}

export default { set, get, clear };
