import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  typography: {
    fontFamily: [
      'Lato',
      'Helvetica Neue',
      'Helvetica',
      'Arial',
      'sans-serif',
    ].join(','),
    useNextVariants: true,
  },
  background: {
    default: '#eef4f8',
  },
  headings: {
    heading1: {
      fontFamily: 'Lato Bold',
      fontSize: 24,
    },
    heading2: {
      fontFamily: 'Lato Bold',
      fontSize: 20,
    },
  },
  // Todo: (eventually) revisit palette, primary/secondary business
  // for a more unified color structure
  palette: {
    primary: {
      light: '#eef5f9',
      main: '#00ADEF',
      dark: '#0047a1',
      contrastText: '#fff',
      border: '#c2daf0',
    },
    secondary: {
      light: '#ffc42f',
      main: '#ff9800',
      contrastText: '#fff',
    },
    text: {
      primary: '#414755',
      secondary: '#949caf',
    },
    surveyStatus: {
      archived: '#FF9800',
      draft: '#B91372',
      prelaunch: '#4cd0e1',
      live: '#00ADEF',
      completed: '#66BB6A',
      inDesign: '#798094',
    },
    campaignDraftStatus: {
      draft: '#B91372',
      underReview: '#7985CC',
      notMeasurable: '#FF9800',
    },
    notifications: {
      groupBackground: 'rgba(238, 245, 249, 0.3)',
      brandOutcome: '#7985cc',
      targetingOutcome: '#f7ca33',
      generalOutcome: '#7ace7e',
    },
    expertTips: '#4BD0E2',
    table: {
      border: '#D5E6F5',
      cellBorder: '#C2DAF0',
      columnSeparator: '#89A5BE',
      headerBackground: '#EFF5F9',
      subHeaderBackground: '#FFFFFF',
      groupHeader: '#C2C6CE',
      groupHeaderBackground: '#F9FCFD',
      borderHighlight: '#005DB0',
      // Cell variants
      secondary: '#A8AFC0',
      emphasis: '#EFEFEF',
      invalid: '#B91372',
      invalidBackground: '#FDF8FB',
    },
    survata: {
      interface: {
        blue1: '#0065C0',
        blue2: '#008BFF',
        blue3: '#00ADEF',
        bluegrey: '#C2DAF0',
        bluegrey2: '#EEF5F9',
        orange: '#FF9800',
        white: '#FFFFFF',
      },
      text: {
        greyblue: '#414755',
        bluegrey: '#949CAF',
        grey: '#E5E5E5',
        white: '#FFFFFF',
      },
      indicators: {
        bluegrey: '#798094',
        green: '#66BB6A',
        blue: '#00ADEF',
        grey: '#E5E5E5',
        orange: '#FF9800',
      },
      checkRadio: {
        orange: '#FF9800',
        bluegrey: '#798094',
      },
      chart: {
        blue1: '#7985CC',
        blue2: '#4CD0E1',
        green: '#82C785',
        yellow: '#F9D355',
        grey: '#E5E5E5',
      },
      icon: {
        bluegrey: '#798094',
        grey: '#E5E5E5',
        darkerGrey: '#D9D9D9',
        red: '#FF3400',
        orange: '#FF9800',
        yellow: '#F9D355',
        white: '#FFFFFF',
        notice: '#C2DAF0',
      },
      googleOauth: {
        main: '#DC4E41',
        hover: '#D73728',
      },
      // Older table styles.
      // To be removed once all tables migrated to `components/Tables/`
      table: {
        blue3: '#D5E6F5',
        tableBorder: '#C2DAF0',
        cellBorder: '#D5E6F5',
        fadedText: '#A8AFC0',
        naText: '#B91372',
        naBackground: '#FEFAFC',
        cellHighlightBorder: '#005DB0',
        positiveStatSigLevel1: '#1E88EF',
        positiveStatSigLevel2: '#64B5F6',
        positiveStatSigLevel3: '#E3F2FD',
        headerBackground: '#EFF5F9',
        subheaderBackground: '#F9FCFD',
        negativeStatSigLevel1: '#616161',
        negativeStatSigLevel2: '#9E9E9E',
        negativeStatSigLevel3: '#EEEEEE',
        white: '#FFFFFF',
      },
    },
  },
  shape: {
    borderRadius: 2, // Override from default Material-UI value (4)
  },
});

export default theme;
