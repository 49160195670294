import { defineMessages } from 'react-intl';

export const scope = 'app.containers.App';

export default defineMessages({
  metaDescription: {
    id: `${scope}.metaDescription`,
    defaultMessage: 'Upwave the strategic insights platform',
  },
});
