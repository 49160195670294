import { stringRemoveHtml } from './html';

/**
 * Limit length of a string. If exceeds limitTo, cut off the rest and add '...'.
 *
 * @param {string} str - the value to manipulate
 * @param {number} limitTo - length at which to truncate
 * @param {boolean} [removeHtml=false] - should we strip HTML before truncating?
 *
 * @returns {string}
 */
export function limitStrLength(str, limitTo, removeHtml = false) {
  const value = removeHtml ? stringRemoveHtml(str) : str;

  if (value.length > limitTo) {
    return `${value.substring(0, limitTo)}...`; // Eventually would love for a true elipses character
  }

  return value;
}

/**
 * Render a format string.
 *
 * Format strings replace {key} in strings with values[key].
 *
 * @param {string} fmt
 * @param {object} values
 *
 * @returns {string}
 */
export function renderFmt(fmt, values) {
  return fmt.replace(/{(\w+)}/g, (_, f) => values[f]);
}

/**
 * Escape a string to use within a RegExp
 *
 * This is from https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_Expressions#Escaping
 * For the full horror, see https://esdiscuss.org/topic/regexp-escape
 *
 * @param {string} src
 *
 * @returns {string}
 */
export function escapeRe(src) {
  return src.replace(/[.*+\-?^${}()|[\]\\]/g, '\\$&');
}

/**
 * Extract nested string.
 *
 * @param {string} strValue
 * @param {string} charFrom
 * @param {string} charTo
 *
 * @returns {string}
 */
export function extract(strValue, charFrom, charTo) {
  const str = strValue.includes('_') ? strValue.split('_')[1] : strValue;
  const start = str.indexOf(charFrom) + 1;
  if (charTo) {
    const end = str.indexOf(charTo);
    if (end === -1) {
      return str.substring(start);
    }
    return str.substr(start, end - start).trim();
  }
  return str.substring(start);
}

/**
 * Extract header name from kpiType.
 *
 * @param {string} str
 *
 * @returns {string}
 */
export function getHeaderName(str) {
  const hasOpenParenthesis = str.includes('(');
  const hasHiphen = str.includes('-');
  const hasUnderScore = str.includes('_');

  if (!hasOpenParenthesis) {
    if (!hasHiphen) {
      if (hasUnderScore) {
        return str.split('_')[0];
      }
      return str;
    }
    return extract(str, '_', '-');
  }
  return extract(str, '_', '(');
}

/**
 * Extract nested name from kpiType.
 *
 * @param {string} str
 *
 * @returns {string}
 */
export function getNestedName(str) {
  const hasOpenParenthesis = str.includes('(');
  const hasHiphen = str.includes('-');
  const hasUnderScore = str.includes('_');
  if (!hasOpenParenthesis) {
    if (!hasHiphen) {
      if (hasUnderScore) {
        return str.split('_')[1];
      }
      return str;
    }
    return extract(str, '-');
  }
  return extract(str, '(', ')');
}
